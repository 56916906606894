<template>
  <div class="login">
    <div class="title">Iniciar Sesión</div>
    <form action class="form" @submit.prevent="login">
      <label class="form-label" for="#username">Usuario:</label>
      <input
        v-model="username"
        class="form-input"
        type="text"
        id="username"
        placeholder="Nombre de usuario"
      >
      <label class="form-label" for="#password">Contraseña:</label>
      <input
        v-model="password"
        class="form-input"
        type="password"
        id="password"
        placeholder="Contraseña"
      >
      <input class="form-submit" type="submit" value="Autenticar">
    </form>
  </div>
</template>

<script>
  import {ACTION, MUTATION} from "../store/mutation-types"


  export default {
    name: "Login",

    data: () => ({
      username: "",
      password: "",
    }),

    methods: {
      login() {
        const user = {username: this.username, password: this.password}

        const notif = this.$q.notify({
          group: false,
          timeout: 0,
          spinner: true,
          message: 'Autenticando...',
        })

        this.$store.dispatch(ACTION.OBTAIN_TOKEN, user)
          .then( () => {
            this.$store.commit(MUTATION.SET_SHOW_LOGIN_DIALOG, {value: false})
            this.username = ''
            this.password = ''

            notif({
              icon: 'done',
              spinner: false,
              type: 'positive',
              message: `Usuario "${user.username}" autenticado`,
              caption: 'Inicio satisfactorio',
              timeout: 3000,
            })
          })
          .catch( () => {
            notif({
              icon: 'error',
              spinner: false,
              type: 'negative',
              message: 'Error al autenticar',
              caption: 'Usuario o contraseña incorrecta',
              timeout: 5000,
            })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    padding: 20px;
  }
  .title {
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 30px;
    color: #148a6a;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    min-width: 350px;
    max-width: 100%;
    background: #eae4e4;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
  }
  .form-label {
    margin-top: 20px;
    color: #2e2e2e;
    margin-bottom: 5px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .form-input {
    padding: 10px 15px;
    background: none;
    background-image: none;
    border: 1px solid #666666;
    color: #383838;
    &:focus {
      outline: 0;
      border-color: #1ab188;
    }
  }
  .form-submit {
    background: #1ab188;
    border: none;
    color: white;
    margin-top: 20px;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.2s;
    &:hover {
      background: #0b9185;
    }
  }
</style>