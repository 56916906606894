<template>
  <q-select outlined
            clearable
            square
            use-input
            v-model="text"
            label="Ruta"
            input-debounce="0"
            filled
            fill-input
            hide-selected
            :options="pathOptions"
            emit-value
            @filter="filterPath"
            @new-value="createNewDirectory"
            :dense="true"
            class="full-width"
            placeholder="Dar enter siempre después de editar"
            style="padding: 0"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          Sin resultados
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>


<script>
  import axios from 'axios'
  import {MUTATION} from "../store/mutation-types";


  export default {
    name: "DirectoryPath",

    data() {
      return {
        pathOptions: [],
        rootPath: {},
      }
    },

    methods: {
      filterPath(val, update) {
        val = val.startsWith('/') ? val : `/${val}`
        val = val.replace(/\/+/g, '/')

        let options = []
        const parts = val.split('/').filter(x => x !== '')

        let index = 0
        let currentDirectory = this.rootPath
        const slashCount = (val.match(/\//g) || []).length

        while (index < slashCount-1) {
          if (!currentDirectory)
            break
          currentDirectory = currentDirectory[parts[index++]]
        }

        const currentText = val.endsWith('/') ? '' : parts[parts.length - 1]

        if (currentDirectory) {
          options = Object.keys(currentDirectory)
            .filter(x => x.startsWith(currentText))
            .map(x => ({
              label: x,
              value: val.substring(0, val.lastIndexOf('/') + 1) + x
            }))
        }

        update(() => {
          this.pathOptions = options || []
        })
      },

      createNewDirectory(val, done) {
        if (val.length > 0) {
          done(val)
        }
      },
    },

    computed: {
      text: {
        get: function () {
          return this.$store.state.directoryTextInput
        },
        set: function (newValue) {
          if (newValue && !newValue.startsWith('/'))
            newValue = "/" + newValue
          this.$store.commit(MUTATION.SET_DIRECTORY_TEXT_INPUT, {value: newValue})
        }
      }
    },

    mounted() {
      const self = this
      const config = {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`
        }
      }

      axios
        .get(`${process.env.VUE_APP_API_HOST}/api/directories/`, config)
        .then(resp => {
          self.rootPath = resp.data.results
        })
        .catch(err => {
          console.log(err)
        })
    },
  }
</script>


<style scoped>
</style>