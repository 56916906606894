export const MUTATION = {
  TOGGLE_LEFT_DRAWER_OPEN: 'TOGGLE_LEFT_DRAWER_OPEN',
  SET_DRAWER_ICON: 'SET_DRAWER_ICON',
  SET_SEARCH_TREE: 'SET_SEARCH_TREE',
  SET_SELECTED_NODE: 'SET_SELECTED_NODE',
  SET_PDF_TEXT_SELECTION: 'SET_PDF_TEXT_SELECTION',
  SET_PDF_CURRENT_PAGE: 'SET_PDF_CURRENT_PAGE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_IN_PDF: 'SET_SEARCH_IN_PDF',
  SET_OLD_SELECTED_NODE: 'SET_OLD_SELECTED_NODE',
  SET_RIGHT_CLICK_NODE: 'SET_RIGHT_CLICK_NODE',
  SET_SHOW_LOAD_PROPERTIES_SPINNER: 'SET_SHOW_LOAD_PROPERTIES_SPINNER',
  SET_SHOW_LOAD_DIRECTORIES_SPINNER: 'SET_SHOW_LOAD_DIRECTORIES_SPINNER',
  SET_PROPERTIES: 'SET_PROPERTIES',
  SET_INITIAL_PROPERTIES_DATA: 'SET_INITIAL_PROPERTIES_DATA',
  SET_SHOW_NEW_TAG_DIALOG: 'SET_SHOW_NEW_TAG_DIALOG',
  SET_NEW_TAG_ITEM: 'SET_NEW_TAG_ITEM',
  SET_SHOW_MODIFY_TAG_DIALOG: 'SET_SHOW_MODIFY_TAG_DIALOG',
  SET_TEXT_SELECTION: 'SET_TEXT_SELECTION',
  SET_SHOW_MODIFY_BUTTONS: 'SET_SHOW_MODIFY_BUTTONS',
  SET_NEW_GAZETTE_OR_NORMATIVE: 'SET_NEW_GAZETTE_OR_NORMATIVE',
  SET_SHOW_UPLOAD_PDF_FILE_INPUT: 'SET_SHOW_UPLOAD_PDF_FILE_INPUT',
  SET_PDF_FILE: 'SET_PDF_FILE',
  SET_CLASSIFICATIONS: 'SET_CLASSIFICATIONS',
  SET_USER: 'SET_USER',
  SET_SHOW_LOGIN_DIALOG: 'SET_SHOW_LOGIN_DIALOG',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  REMOVE_TOKEN: 'REMOVE_TOKEN',
  RESET_STATE: 'RESET_STATE',
  SET_EDITOR_ICON: 'SET_EDITOR_ICON',
  SET_DIRECTORY_TEXT_INPUT: 'SET_DIRECTORY_TEXT_INPUT',
  SET_RELOAD_GAZETTES: 'SET_RELOAD_GAZETTES',
}

export const ACTION = {
  OBTAIN_TOKEN: 'OBTAIN_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  INSPECT_TOKEN: 'INSPECT_TOKEN',
  SET_REVISION_STATE: 'SET_REVISION_STATE',
}
