import { render, staticRenderFns } from "./TextEditor.vue?vue&type=template&id=97d905ba&"
import script from "./TextEditor.vue?vue&type=script&lang=js&"
export * from "./TextEditor.vue?vue&type=script&lang=js&"
import style0 from "./TextEditor.vue?vue&type=style&index=0&id=97d905ba&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QEditor,QFab,QFabAction,QBtn});
