import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { MUTATION, ACTION } from './mutation-types'
import {stringfyJson, deepEqual} from '../util'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    user: null,
    pdfFile: null,
    properties: [],
    searchTree: '',
    searchText: '',
    searchInPdf: '',
    newTagCounter: 0,
    pdfCurrentPage: 1,
    textSelection: '',
    rightClickNode: {},
    selectedNode: null,
    setEditorIcon: false,
    pdfTextSelection: '',
    leftDrawerOpen: true,
    reloadGazettes: false,
    classifications: null,
    oldSelectedNode: null,
    showLoginDialog: true,
    directoryTextInput: '',
    showNewTagDialog: false,
    showModifyButtons: false,
    initialPropertiesData: {},
    showModifyTagDialog: false,
    newGazetteOrNormative: null,
    showUploadPdfFileInput: false,
    showLoadPropertiesSpinner: true,
    showLoadDirectoriesSpinner: true,
    drawerIcon: 'keyboard_arrow_left',
    newTagItem: {id: null, tag:{id: null, name:'', value:''}, text:'', valueOptions: []},
    jwt: sessionStorage.getItem('juridicJWT'),
    endpoints: {
      obtainJWT: `${process.env.VUE_APP_API_HOST}/api/auth/obtain_token/`,
      refreshJWT: `${process.env.VUE_APP_API_HOST}/api/auth/refresh_token/`
    },
    adminUrl: `${process.env.VUE_APP_API_HOST}/admin/`,
  },

  mutations: {
    [MUTATION.RESET_STATE](state) {
      state.user = null
      state.pdfFile = null
      state.properties = []
      state.searchTree = ''
      state.searchText = ''
      state.searchInPdf = ''
      state.newTagCounter = 0
      state.pdfCurrentPage = 1
      state.textSelection = ''
      state.rightClickNode = {}
      state.selectedNode = null
      state.pdfTextSelection = ''
      state.leftDrawerOpen = true
      state.reloadGazettes = false
      state.classifications = null
      state.oldSelectedNode = null
      state.showLoginDialog = true
      state.directoryTextInput = ''
      state.showNewTagDialog = false
      state.showModifyButtons = false
      state.initialPropertiesData = {}
      state.showModifyTagDialog = false
      state.newGazetteOrNormative = null
      state.showUploadPdfFileInput = false
      state.showLoadPropertiesSpinner = true
      state.showLoadDirectoriesSpinner = true
    },
    [MUTATION.TOGGLE_LEFT_DRAWER_OPEN](state) {
      state.leftDrawerOpen = !state.leftDrawerOpen
    },
    [MUTATION.SET_DRAWER_ICON](state, payload) {
      state.drawerIcon = payload.value
    },
    [MUTATION.SET_SEARCH_TREE](state, payload) {
      state.searchTree = payload.value
    },
    [MUTATION.SET_SELECTED_NODE](state, payload) {
      state.selectedNode = payload
    },
    [MUTATION.SET_PDF_TEXT_SELECTION](state, payload) {
      state.pdfTextSelection = payload.value
    },
    [MUTATION.SET_PDF_CURRENT_PAGE](state, payload) {
      state.pdfCurrentPage = payload.value
    },
    [MUTATION.SET_SEARCH_TEXT](state, payload) {
      state.searchText = payload.value
    },
    [MUTATION.SET_SEARCH_IN_PDF](state, payload) {
      state.searchInPdf = payload.value
    },
    [MUTATION.SET_OLD_SELECTED_NODE](state, payload) {
      state.oldSelectedNode = payload.value
    },
    [MUTATION.SET_RIGHT_CLICK_NODE](state, payload) {
      state.rightClickNode = payload
    },
    [MUTATION.SET_SHOW_LOAD_PROPERTIES_SPINNER](state, payload) {
      state.showLoadPropertiesSpinner = payload.value
    },
    [MUTATION.SET_SHOW_LOAD_DIRECTORIES_SPINNER](state, payload) {
      state.showLoadDirectoriesSpinner = payload.value
    },
    [MUTATION.SET_PROPERTIES](state, payload) {
      state.properties = payload.value
    },
    [MUTATION.SET_INITIAL_PROPERTIES_DATA](state, payload) {
      state.initialPropertiesData = payload
    },
    [MUTATION.SET_SHOW_NEW_TAG_DIALOG](state, payload) {
      state.showNewTagDialog = payload.value
    },
    [MUTATION.SET_NEW_TAG_ITEM](state, payload) {
      state.newTagItem = payload
    },
    [MUTATION.SET_SHOW_MODIFY_TAG_DIALOG](state, payload) {
      state.showModifyTagDialog = payload.value
    },
    [MUTATION.SET_TEXT_SELECTION](state, payload) {
      state.textSelection = payload.value
    },
    [MUTATION.SET_SHOW_MODIFY_BUTTONS](state, payload) {
      state.showModifyButtons = payload.value
    },
    [MUTATION.SET_NEW_GAZETTE_OR_NORMATIVE](state, payload) {
      state.newGazetteOrNormative = payload.value
    },
    [MUTATION.SET_SHOW_UPLOAD_PDF_FILE_INPUT](state, payload) {
      state.showUploadPdfFileInput = payload.value
    },
    [MUTATION.SET_PDF_FILE](state, payload) {
      state.pdfFile = payload
    },
    [MUTATION.SET_CLASSIFICATIONS](state, payload) {
      state.classifications = payload
    },
    [MUTATION.SET_USER](state, payload) {
      state.user = payload
      if (state.user)
        sessionStorage.setItem('juridicUser', JSON.stringify(state.user))
    },
    [MUTATION.SET_SHOW_LOGIN_DIALOG](state, payload) {
      state.showLoginDialog = payload.value
    },
    [MUTATION.UPDATE_TOKEN](state, newToken) {
      sessionStorage.setItem('juridicJWT', newToken)
      state.jwt = newToken
    },
    [MUTATION.REMOVE_TOKEN](state) {
      state.jwt = null
      state.user = null
      sessionStorage.removeItem('juridicJWT')
      sessionStorage.removeItem('juridicUser')
    },
    [MUTATION.SET_EDITOR_ICON](state, payload) {
      state.setEditorIcon = payload.value
    },
    [MUTATION.SET_DIRECTORY_TEXT_INPUT](state, payload) {
      state.directoryTextInput = payload.value
    },
    [MUTATION.SET_RELOAD_GAZETTES](state, payload) {
      state.reloadGazettes = payload.value
    },
  },

  actions: {
    [ACTION.OBTAIN_TOKEN](state, {username, password}) {
      const payload = {
        username: username,
        password: password
      }

      return axios.post(this.state.endpoints.obtainJWT, payload)
        .then( async (response) => {
          const config = {
            headers: {
              Authorization: `JWT ${response.data.token}`
            }
          }
          const decodedUser = jwt_decode(response.data.token)
          const userDetails = (await axios.get(`${process.env.VUE_APP_API_HOST}/api/users/${decodedUser.user_id}/`, config)).data
          const user = {...userDetails, ...decodedUser}

          this.commit(MUTATION.UPDATE_TOKEN, response.data.token)
          this.commit(MUTATION.SET_USER, user)
        })
        .catch( (err) => {
          this.commit(MUTATION.REMOVE_TOKEN)
          throw err
        })
    },

    [ACTION.REFRESH_TOKEN]() {
      const payload = {
        token: this.state.jwt
      }

      return axios.post(this.state.endpoints.refreshJWT, payload)
        .then( (response) => {
          this.commit(MUTATION.UPDATE_TOKEN, response.data.token)
        })
        .catch( (err) => {
          this.commit(MUTATION.REMOVE_TOKEN)
          throw err
        })
    },

    [ACTION.INSPECT_TOKEN]() {
      const token = this.state.jwt;

      if(token) {
        const decoded = jwt_decode(token)
        const exp = decoded.exp
        const orig_iat = decoded.orig_iat
        const now = Date.now()/1000
        const refresh_limit = 12*60*60
        const refresh_time = now - orig_iat

        if (now < exp && refresh_time > refresh_limit) {
          this.dispatch(ACTION.REFRESH_TOKEN)
        }
        else if (now > exp) {
          this.commit(MUTATION.REMOVE_TOKEN)
        }
      }
    },

    [ACTION.SET_REVISION_STATE](state, {revision, isRightClick=false}) {
      if (!this.state.user)
        return

      let gazetteId = null
      if (!isRightClick && this.state.selectedNode)
        gazetteId = this.state.selectedNode.split('/')[4]
      if (isRightClick && this.state.rightClickNode)
        gazetteId = this.state.rightClickNode.nodeId
      if (!gazetteId)
        return

      const config = {
        headers: {
          Authorization: `JWT ${this.state.jwt}`
        }
      }
      const self = this

      revision.lastreviewpage = revision.lastreviewpage | this.state.pdfCurrentPage
      const gazetteUrl = `${process.env.VUE_APP_API_HOST}/api/gazettes/${gazetteId}/`

      axios.get(gazetteUrl).then( async resp => {
        let revisionId =  resp.data.revision
        const revisionUrl = `${process.env.VUE_APP_API_HOST}/api/revisions/`

        if (!revisionId) { // create new revision
          const revisionResp = await axios.post(revisionUrl, revision, config)
          await axios.patch(gazetteUrl, {revision: revisionResp.data.id}, config)
          await axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
            "action": "create",
            "instance": "revision",
            "tovalue": stringfyJson(revision, '\n'),
            "user": self.state.user.id,
            "details": `create revision for gazette\nid: ${gazetteId}`
          }, config)
        }
        else { // update revision
          const revRespPrevious = await axios.get(`${revisionUrl}${revisionId}/`, config)
          const revResp = await axios.patch(`${revisionUrl}${revisionId}/`, revision, config)

          if (!deepEqual(revRespPrevious.data, revResp.data)) {
            await axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
              "action": "update",
              "instance": "revision",
              "fromvalue": stringfyJson(revRespPrevious.data, '\n'),
              "tovalue": stringfyJson(revResp.data, '\n'),
              "user": self.state.user.id,
              "details": `update revision for gazette\nid: ${gazetteId}`
            }, config)
          }
        }
      })
    },
  },

  modules: {}
})
