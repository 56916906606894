import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=7b6d5f0c&"
import script from "./Editor.vue?vue&type=script&lang=js&"
export * from "./Editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QPage,QSplitter,QEditor,QHeader});
