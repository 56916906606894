<template>

  <!-- Text editor with floating button -->
  <div id="text-editor-container" class="q-pa-md q-gutter-sm">

    <!-- Text Editor -->
    <q-editor id="text-editor"
              v-model="text"
              :height="height"
              placeholder="No se ha cargado ningún pdf"
              :definitions="editorConfigDefinitions"
              :toolbar="editorConfigToolbar"
    >
    </q-editor>

    <!-- Floating button -->
    <q-fab v-if="showFloatingButton"
           direction="up"
           color="primary"
           v-model="closedFab"
           icon="keyboard_arrow_up"
           vertical-actions-align="right"
           padding="sm"
           style="float: right; top: -60px; left: -30px; height: 0; margin: 0;"
    >
      <q-fab-action label-position="left" color="green" @click="addNewTag" icon="add"
                    label="Nueva Etiqueta" style="padding: 0">
      </q-fab-action>
      <q-fab-action label-position="left" label="Modificar Etiqueta" @click="modifyTag"
                    style="background-color: #f32197; color: white; padding: 0;">
      </q-fab-action>
    </q-fab>

  </div>

</template>


<script>
  import axios from 'axios'
  import {
    NodeType,
    stringfyJson,
  } from '../util'
  import {MUTATION, ACTION} from '../store/mutation-types'


  export default {
    name: 'TextEditor',

    data() {
      return {
        text: '',
        height: `266px`,
        currentPage: -1, // to capture previous page and can save change on gazettePages object
        closedFab: true,
        gazettePages: {}, // pages like {page: text}
        gazetteData: null,
        currentGazetteId: null,
        selectedNodeType: null,
        showFloatingButton: false,
        firstTimeLoadGazette: true,
        editorConfigDefinitions: {
          save: {
            tip: 'Guardar los cambios',
            icon: 'save',
            handler: this.saveChangeOnTextEditor
          }
        },
        editorConfigToolbar: [
          [
            {
              icon: this.$q.iconSet.editor.align,
              fixedLabel: true,
              list: 'only-icons',
              options: ['left', 'center', 'right', 'justify']
            },
            {
              icon: this.$q.iconSet.editor.bold,
              list: 'only-icons',
              fixedLabel: true,
              options: ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript']
            }
          ],
          ['link', 'fullscreen'],
          ['undo', 'redo', 'viewsource'],
          ['save']
        ],
      }
    },

    methods: {
      saveChangeOnTextEditor() {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        const selected = this.$store.state.selectedNode

        const notif = this.$q.notify({
          group: false, // required to be updatable
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: 'Guardando Cambios',
          caption: selected ? selected.id : 'No se ha seleccionado gaceta'
        })

        if (!selected) {
          notif({
            icon: 'warning',
            spinner: false,
            message: 'No se ha seleccionado gaceta',
            timeout: 2000
          })
        } else {
          this.gazettePages[this.currentPage] = this.text

          if (this.gazetteData) {
            for (const dataItem of this.gazetteData) {
              dataItem.text = this.gazettePages[dataItem.page]
            }
          }

          // make header request with authentication token
          const config = {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`
            }
          }
          const data = {data: this.gazetteData}
          const self = this

          axios
            .patch(`${process.env.VUE_APP_API_HOST}/api/gazettes/${this.currentGazetteId}/`, data, config)
            .then( () => {
              this.$store.dispatch(ACTION.SET_REVISION_STATE, {revision: {
                state: 'editing',
                editor: this.$store.state.user ? this.$store.state.user.id : null
              }})
              this.$store.commit(MUTATION.SET_EDITOR_ICON, { value: true })

              axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
                "action": "update",
                "instance": 'gazette',
                "user": self.$store.state.user.id,
                "details": `modify text of gazette\nid: ${self.currentGazetteId}`
              }, config)

              notif({
                icon: 'done',
                spinner: false, // we reset the spinner setting so the icon can be displayed
                message: 'Cambios realizados!',
                timeout: 2500
              })
            })
            .catch( (err) => {
              let errorText = 'error desconocido'
              if (err && err.response) {
                errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
                  : stringfyJson(err.response.data)
              }

              notif({
                icon: 'error',
                type: 'negative',
                spinner: false,
                message: 'Error guardando el texto',
                html: true,
                caption: errorText,
                timeout: 5000
              })
            })
        }
      },

      changeText() {
        if (!this.firstTimeLoadGazette) { // prevent that the first time page 1 is set to empty string
          this.gazettePages[this.currentPage] = this.text
        }

        if (Object.keys(this.gazettePages).includes(`${this.currentPage}`)) {
          this.currentPage = this.pdfCurrentPage
          this.text = this.gazettePages[this.currentPage] || ''
        }
      },

      addNewTag() {
        this.showModifyButtons = false

        this.$store.commit(MUTATION.SET_NEW_TAG_ITEM, {
          tag: {
            name: '',
            value: ''
          },
          text: this.textSelection
        })
        this.$store.commit(MUTATION.SET_SHOW_NEW_TAG_DIALOG, {value: true})
        this.showFloatingButton = false
      },

      modifyTag() {
        this.$store.commit(MUTATION.SET_SHOW_MODIFY_TAG_DIALOG, {value: true})
      }
    },

    computed: {
      selectedNode() {
        return this.$store.state.selectedNode
      },
      pdfCurrentPage() {
        return this.$store.state.pdfCurrentPage
      },
      textSelection: {
        get: function () {
          return this.$store.state.textSelection
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_TEXT_SELECTION, {value: newValue})
        }
      },
      showModifyButtons: {
        get: function () {
          return this.$store.state.showModifyButtons
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_MODIFY_BUTTONS, {value: newValue})
        }
      },
    },

    watch: {
      selectedNode() {
        if (!this.selectedNode) { // unselected node
          return
        }
        this.selectedNodeType = this.selectedNode.split('/')[2]

        const gazetteId = this.selectedNode.split('/')[4]

        if (gazetteId === this.currentGazetteId) { // is the same, no need to load again
          return
        }

        this.text = ''
        this.gazettePages = {}
        this.firstTimeLoadGazette = true
        this.currentPage = 1

        axios
          .get(`${process.env.VUE_APP_API_HOST}/api/gazettes/${gazetteId}/`)
          .then((res) => {
            this.gazetteData = res.data.data

            for (const pageItem of this.gazetteData) { // reformat pdf data in object like { page: text}
              this.gazettePages[pageItem.page] = pageItem.text
            }
            this.currentGazetteId = gazetteId
            this.currentPage = this.pdfCurrentPage || 1
            this.changeText()
            this.firstTimeLoadGazette = false
          })
          .catch()
      },

      pdfCurrentPage() {
        this.changeText()
      },
    },

    mounted() {
      document.getElementById('text-editor').addEventListener('click', () => {
        this.textSelection = document.getSelection().toString()
        this.showFloatingButton = (this.textSelection !== ''
                                && this.selectedNodeType
                                && this.selectedNodeType === NodeType.NORMATIVE)

        this.closedFab = false
      })
    }
  }
</script>


<style>
  #text-editor-container {
    padding: 0;
  }

  #text-editor, .q-editor__content {
    min-height: 0 !important;
  }

  .q-btn--fab-mini .q-btn__wrapper {
    padding: 4px 9px !important;
    min-height: 0 !important;
  }
</style>