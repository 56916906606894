<template>
  <div>
    <div id="pdf-viewer-container">
      <pdf
        :pdf="pdfUrl"
        @open="openHandler"
        :config="pdfComponentConfig"
      >
      </pdf>
    </div>

    <q-fab v-if="showFloatingButton"
           direction="up"
           color="primary"
           v-model="closedFab"
           icon="keyboard_arrow_up"
           vertical-actions-align="right"
           padding="sm"
           style="float: right; top: -60px; left: -30px; height: 0; margin: 0;"
    >
      <q-fab-action label-position="left" color="green" @click="addNewTag" icon="add"
                    label="Nueva Etiqueta" style="padding: 0">
      </q-fab-action>
      <q-fab-action label-position="left" label="Modificar Etiqueta" @click="modifyTag"
                    style="background-color: #f32197; color: white; padding: 0;">
      </q-fab-action>
    </q-fab>
  </div>
</template>


<script>
  import PdfViewer from 'vue-pdf-app'
  import { MUTATION } from '../store/mutation-types'
  import axios from 'axios'
  import {NodeType} from '../util'
  import {QSpinnerHourglass} from 'quasar'


  export default {
    name: 'PdfViewer',

    components: {
      pdf: PdfViewer
    },

    data() {
      return {
        pdf: null,
        pdfUrl: '',
        closedFab: true,
        selectedNodeType: null,
        showFloatingButton: false,
        pdfComponentConfig: {
          sidebar: {
            viewThumbnail: true,
            viewOutline: true,
            viewAttachments: false
          },
          findbar: true,
          secondaryToolbar: {
            secondaryPresentationMode: false,
            secondaryOpenFile: false,
            secondaryPrint: true,
            secondaryDownload: true,
            secondaryViewBookmark: false,
            firstPage: false,
            lastPage: false,
            pageRotateCw: true,
            pageRotateCcw: true,
            cursorSelectTool: true,
            cursorHandTool: true,
            scrollVertical: false,
            scrollHorizontal: false,
            scrollWrapped: false,
            spreadNone: false,
            spreadOdd: false,
            spreadEven: false,
            documentProperties: false
          },
          toolbar: {
            toolbarViewerLeft: {
              previous: true,
              next: true,
              pageNumber: true
            },
            toolbarViewerRight: {
              presentationMode: true,
              openFile: false,
              print: true,
              download: true,
              viewBookmark: false,
              secondaryToolbarToggle: false
            },
            toolbarViewerMiddle: false
          },
          viewerContextMenu: true,
          errorWrapper: false
        },
      }
    },

    computed: {
      currentPage() {
        return this.pdf.pdfViewer.currentPageNumber
      },
      selectedNode() {
        return this.$store.state.selectedNode
      },
      oldSelectedNode() {
        return this.$store.state.oldSelectedNode
      },
      searchInPdf() {
        return this.$store.state.searchInPdf
      },
      textSelection: {
        get: function () {
          return this.$store.state.textSelection
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_TEXT_SELECTION, {value: newValue})
        }
      },
    },

    watch: {
      selectedNode() {
        if (!this.selectedNode)
          return

        this.selectedNodeType = this.selectedNode.split('/')[2]

        const nodeId = this.selectedNode ? this.selectedNode.split('/') : null
        const oldNodeId = this.oldSelectedNode ? this.oldSelectedNode.split('/') : null

        if (nodeId && nodeId.length > 1) {

          if (oldNodeId && oldNodeId.length > 1 && nodeId[1] === oldNodeId[1]) {
            this.$q.notify({
              icon: 'warning', // we add an icon
              spinner: false, // we reset the spinner setting so the icon can be displayed
              message: 'Esta Gaceta ya se está visualizando!',
              caption: nodeId[1],
              timeout: 1500 // we will timeout it in 2.5s
            })
            return
          }

          const pdfUrl = `${process.env.VUE_APP_API_HOST}/files/${nodeId[1]}`

          const notif = this.$q.notify({
            group: false, // required to be updatable
            timeout: 0, // we want to be in control when it gets dismissed
            spinner: QSpinnerHourglass,
            message: 'Descargando Gaceta',
            caption: nodeId[1]
          })

          axios
            .get(pdfUrl, {responseType: 'arraybuffer'})
            .then(res => {
              notif({
                icon: 'done', // we add an icon
                spinner: false, // we reset the spinner setting so the icon can be displayed
                message: 'Descarga completada!',
                timeout: 1500 // we will timeout it in 2.5s
              })

              this.$store.commit(MUTATION.SET_OLD_SELECTED_NODE, {value: this.selectedNode})
              this.pdfUrl = res.data
            })
            .catch(() => {
              notif({
                icon: 'hide_source', // we add an icon
                spinner: false, // we reset the spinner setting so the icon can be displayed
                message: 'El pdf de esta gaceta no existe!',
                timeout: 1500 // we will timeout it in 2.5s
              })
            })
        }
      },
      searchInPdf() {
        this.searchPDF(this.searchInPdf)
      }
    },

    methods: {
      searchPDF(text) {
        return new Promise((resolve) => {
          this.pdf.findBar.findField.value = text

          document.getElementById("findHighlightAll").setAttribute('checked', true)

          const event = new CustomEvent('searchInPdf', {
            query: text,
            caseSensitive: document.getElementById("findMatchCase").getAttribute('checked'),
            highlightAll: true,
            findPrevious: undefined
          })

          this.pdf.findBar.dispatchEvent(event)
          resolve()
        })
      },

      openHandler(PDFViewerApplication) {
        this.pdf = PDFViewerApplication
        this.pdf.numPages = 1
        try {
          this.pdf.viewerPrefs.showPreviousViewOnLoad = false
        } catch { /*continue*/
        }
        this.$store.commit(MUTATION.SET_PDF_CURRENT_PAGE, {value: this.currentPage})
      },

      addNewTag() {
        this.showModifyButtons = false

        this.$store.commit(MUTATION.SET_NEW_TAG_ITEM, {
          tag: {
            name: '',
            value: ''
          },
          text: this.textSelection
        })
        this.$store.commit(MUTATION.SET_SHOW_NEW_TAG_DIALOG, {value: true})
        this.showFloatingButton = false
      },

      modifyTag() {
        this.$store.commit(MUTATION.SET_SHOW_MODIFY_TAG_DIALOG, {value: true})
      },
    },

    mounted() {
      document.getElementById('viewerContainer').addEventListener('scroll', () => {
        if (this.$store.state.pdfCurrentPage !== this.currentPage) {
          this.$store.commit(MUTATION.SET_PDF_CURRENT_PAGE, {value: this.currentPage})
        }
      })

      document.getElementById('viewerContainer').addEventListener('click', () => {
        this.textSelection = document.getSelection().toString()
        this.$store.commit(MUTATION.SET_PDF_TEXT_SELECTION, {value: this.textSelection})

        this.showFloatingButton = (this.textSelection !== ''
          && this.selectedNodeType
          && this.selectedNodeType === NodeType.NORMATIVE)

        this.closedFab = false
      })
    }
  }
</script>


<style>
  #pdf div {
    background-color: #7a9692;
    background-image: none;
    color: black;
  }

  #pdf .textLayer {
    background-color: white;
  }

  #pdf-viewer-container {
    position: relative;
    z-index: 0;
    height: 300px;
  }

  .toolbarButtonSpacer {
    display: none;
  }
</style>