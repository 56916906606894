<template>
  <q-layout view="hHh Lpr fFf">

    <!-- Header and main page editor-->
    <q-page-container v-if="$store.state.user">

      <!-- Header bar -->
      <q-header elevated>
        <q-toolbar>
          <!-- Toggle sidebar buton-->
          <q-btn id="drawer-btn"
                 unelevated
                 dense
                 round
                 @click="toggleDrawer"
                 aria-label="Menu"
                 :icon="$store.state.drawerIcon"
          ></q-btn>

          <!-- Header text -->
          <div v-if="$store.state.selectedNode" style="margin-left: 15px;">
            <div>{{ headerText }}</div>
          </div>

          <q-space />

          <!-- Search box -->
          <q-input v-model="searchText"
                   dark
                   dense
                   standout
                   input-class="text-right"
                   class="q-ml-md"
                   @keyup.esc="searchText=''; searchInPdf"
                   @keyup.enter="searchInPdf"
                   style="margin-right: 10px;"
          >
            <template v-slot:append>
              <q-icon v-if="searchText === ''" name="search" />
              <q-icon v-else name="clear" class="cursor-pointer" @click="searchText = ''" />
            </template>
          </q-input>

          <!-- Update DB -->
          <q-btn v-if="user && user.is_admin"
                 no-caps
                 flat
                 :loading="loadingUpdatedDb"
                 @click="updateDB"
                 style="background-color: #19aa96; width: 160px;"
          >
            <template v-slot:loading>
              <q-spinner-ios class="on-left" />
              <span style="padding-top: 1px">Actualizando</span>
            </template>
            <div class="row items-center no-wrap" style="bottom: 1px;">
              <q-icon left name="cached" />
              <div class="text-center" style="padding-top: 2px">
                Actualizar DB
              </div>
            </div>
          </q-btn>

          <!-- Log in/out button -->
          <q-btn id="login-btn" no-caps flat @click="toggleLoginDialog">
            <span v-if="!$store.state.user">Iniciar sesión</span>
            <div id='login-btn-text' v-else>
              Salir {{ `(${$store.state.user ? $store.state.user.username : ''})` }}
            </div>
          </q-btn>

          <!-- Django admin link -->
          <a v-if="user && user.is_admin" :href="$store.state.adminUrl" target="_blank" style="position: relative; left: 8px;">
            <q-icon id="go-to-admin" name="manage_accounts" size="sm"></q-icon>
          </a>

        </q-toolbar>
      </q-header>

      <editor style="padding: 2px;">
      </editor>

    </q-page-container>

    <!-- Login Dialog -->
    <q-dialog persistent v-model="showLoginDialog">
      <q-card>
        <q-card-section>
          <login></login>
        </q-card-section>
      </q-card>
    </q-dialog>

  </q-layout>
</template>

<script>
  import axios from 'axios'
  import Editor from "./views/Editor.vue"
  import Login from "./components/Login"
  import { MUTATION, ACTION } from "./store/mutation-types"
  import {NodeType} from './util'


  export default {
    name: 'DefaultLayout',

    components: {
      Editor,
      Login,
    },

    data() {
      return {
        headerText: '',
        loadingUpdatedDb: false,
      }
    },

    methods: {
      toggleDrawer() {
        this.$store.commit(MUTATION.TOGGLE_LEFT_DRAWER_OPEN)
      },
      searchInPdf() {
        this.$store.commit(MUTATION.SET_SEARCH_IN_PDF, {value: this.$store.state.searchText})
      },
      toggleLoginDialog() {
        if (this.$store.state.user) {
          this.logout()
        }
        this.showLoginDialog = true
      },
      logout() {
        this.$store.commit(MUTATION.REMOVE_TOKEN)
        this.$store.commit(MUTATION.SET_USER, null)

        this.$store.commit(MUTATION.RESET_STATE)

        this.$q.notify({
          icon: 'done',
          type: 'positive',
          message: 'Sesión cerrada',
        })
      },
      updateDB() {
        this.loadingUpdatedDb = true
        const self = this
        const currentYear = new Date().getFullYear()
        const url = `${process.env.VUE_APP_API_HOST}/api/script/update/db/?year=${currentYear}&use_proxy=${true}`
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        axios.get(url, config)
          .then(async (res) => {
            self.loadingUpdatedDb = false

            self.$q.dialog({
              title: 'Actualización terminada',
              message: 'Desea recargar las gacetas? Puede perder las modificaciones no guardadas',
              cancel: true,
              persistent: true
            }).onOk(() => {

              console.log(res.data)

              // for reload gazettes
              self.reloadGazettes = true
              setTimeout(()=> {
                self.reloadGazettes = false
              }, 10)

            }).onDismiss(() => {
              self.$q.notify({
                type: 'positive',
                icon: 'done',
                message: `Actualización Satisfactoria!`,
                timeout: 2500
              })
            })
          })
          .catch(e => {
            self.loadingUpdatedDb = false
            self.$q.notify({
              type: 'negative',
              icon: 'error',
              message: `Error actualizando Gacetas: ${e}`,
              timeout: 2500
            })
          })
      },
    },

    computed: {
      user() {
        return this.$store.state.user
      },
      leftDrawerOpen() {
        return this.$store.state.leftDrawerOpen
      },
      selectedNode() {
        return this.$store.state.selectedNode
      },
      searchText: {
        get() {
          return this.$store.state.searchText
        },
        set(value) {
          this.$store.commit(MUTATION.SET_SEARCH_TEXT, {value: value})
          if (value === '')
            this.$store.commit(MUTATION.SET_SEARCH_IN_PDF, {value: value})
        }
      },
      showLoginDialog: {
        get() {
          return this.$store.state.showLoginDialog
        },
        set(value) {
          this.$store.commit(MUTATION.SET_SHOW_LOGIN_DIALOG, {value: value})
        }
      },
      reloadGazettes: {
        get() {
          return this.$store.state.reloadGazettes
        },
        set(value) {
          this.$store.commit(MUTATION.SET_RELOAD_GAZETTES, {value: value})
        }
      },
    },

    watch: {
      leftDrawerOpen(newValue) {
        let icon = newValue ? "keyboard_arrow_left" : "keyboard_arrow_right"
        this.$store.commit(MUTATION.SET_DRAWER_ICON, {value: icon})
      },
      selectedNode(newValue) {
        if (!newValue)
          return

        const nodeId = newValue.split('/')

        if (nodeId[2] === NodeType.NORMATIVE)
          this.headerText = `${nodeId[5]} / ${nodeId[3]}`
        else
          this.headerText = nodeId[3]
      },
    },

    mounted() {
      this.$store.dispatch(ACTION.INSPECT_TOKEN)

      let user = sessionStorage.getItem('juridicUser')
      if (user) {
        user = JSON.parse(user)
        this.$store.commit(MUTATION.SET_USER, user)
        this.showLoginDialog = false
      }
    }
  }
</script>

<style>
  #drawer-btn {
    background-color: #51918599;
  }

  #login-btn {
    background-color: #044a4d;
    color: #fff;
    margin-left: 10px;
    border: 1px solid #a4b3b0;
  }

  #login-btn-text {
    display: ruby;
  }

  #go-to-admin {
    color: white;
  }

  #go-to-admin:hover {
    color: #baffe8;
  }
</style>