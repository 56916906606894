<template>
  <div>

    <!-- Tree (Left Sidebar) -->
    <q-drawer v-model="$store.state.leftDrawerOpen"
              bordered
              show-if-above
              content-class="bg-grey-2"
    >
      <q-page>
        <tree-explorer>
        </tree-explorer>
      </q-page>
    </q-drawer>

    <!-- Splitter (pdf-text, classifiers) -->
    <div>
      <q-splitter id="horizontalSplitter"
                  v-model="horizontalSplitterModel"
                  horizontal
                  :limits="[6, 87]"
                  before-class="overflow-sroll"
                  after-class="overflow-scrool"
                  style="height: 500px;"
                  @input="updateHeightOnHorizontalSplitter"
      >

        <!-- Splitter (pdf, text) -->
        <template v-slot:before>
          <q-splitter id="verticalSplitter"
                      v-model="verticalSplitterModel"
                      :limits="[35, 75]"
                      @input="updateHeightOnVerticalSplitter"
          >

            <!-- Pdf viewer-->
            <template v-slot:before>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <pdf-viewer>
                </pdf-viewer>
              </div>
            </template>

            <!-- Text Editor-->
            <template v-slot:after>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <text-editor>
                </text-editor>
              </div>
            </template>
          </q-splitter>
        </template>

        <!-- Classifiers -->
        <template v-slot:after>
          <div class="row" style="padding: 5px">
            <div v-if="isCurrentNodeNormative()" class="col-xl col-lg col-md col-sm-12">
              <classifiers>
              </classifiers>
            </div>
          </div>
        </template>

      </q-splitter>
    </div>

  </div>
</template>


<script>
  import TreeExplorer from '../components/TreeExplorer'
  import TextEditor from '../components/TextEditor'
  import PdfViewer from '../components/PdfViewer'
  import Classifiers from '../components/Classifiers'
  import {NodeType} from '../util'
  import {MUTATION} from '../store/mutation-types'


  export default {
    name: 'Editor',

    components: {
      Classifiers,
      TreeExplorer,
      TextEditor,
      PdfViewer,
    },

    data() {
      return {
        horizontalSplitterModel: 65,
        verticalSplitterModel: 75,
      }
    },

    methods: {
      isCurrentNodeNormative() {
        if (this.$store.state.selectedNode)
          return this.$store.state.selectedNode.split('/')[2] === NodeType.NORMATIVE
        return false
      },
      updateHeightOnHorizontalSplitter(value) {
        const splitterHeight = document.getElementById('horizontalSplitter').offsetHeight || window.innerHeight
        const height = splitterHeight * (value / 100) - 1

        const qEditorToolbarHeight = document.querySelector('.q-editor__toolbar').offsetHeight + 2

        document.getElementById('pdf-viewer-container').style.cssText = `height: ${height}px !important`
        document.getElementById('text-editor').style.cssText = `height: ${height}px !important`
        document.querySelector('.q-editor__content').style.cssText = `height: ${height - qEditorToolbarHeight}px !important;`
      },
      updateHeightOnVerticalSplitter() {
        const qEditorToolbar = document.querySelector('.q-editor__toolbar')
        const qEditorContent = document.querySelector('.q-editor__content')
        const verticalSplitter = document.getElementById('verticalSplitter')

        const qEditorHeight = qEditorToolbar.offsetHeight + qEditorContent.offsetHeight
        const qEditorContentHeight = (verticalSplitter.offsetHeight - 2) - qEditorToolbar.offsetHeight
        if (qEditorHeight !== (verticalSplitter.offsetHeight - 2)) {
          qEditorContent.style.cssText = `height: ${qEditorContentHeight}px !important;`
        }
      }
    },

    mounted() {
      this.$store.commit(MUTATION.SET_SEARCH_TEXT, {value: ''})
      this.$store.commit(MUTATION.SET_SEARCH_IN_PDF, {value: ''})

      const headerSize = document.getElementsByClassName('q-header')[0].clientHeight + 5

      setTimeout(()=>{
        document.getElementById('horizontalSplitter')
          .style.cssText = `height: ${window.innerHeight - headerSize}px !important`
        this.updateHeightOnHorizontalSplitter(this.horizontalSplitterModel)
      }, 10)

      const self = this
      window.addEventListener('resize', function () {
        document.getElementById('horizontalSplitter')
          .style.cssText = `height: ${window.innerHeight - headerSize}px !important`
        self.updateHeightOnHorizontalSplitter(self.horizontalSplitterModel)
      })
    }
  }
</script>


<style>
</style>
