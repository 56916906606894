import axios from 'axios'


const NodeType = {
  YEAR: 'YEAR',
  GAZETTE: 'GAZETTE',
  NORMATIVE: 'NORMATIVE'
}

const GazetteEditionType = {
  "Edición Especial": "es",
  "Extraordinaria": "ex",
  "Extraordinaria Especial": "exe",
  "Ordinaria": "o",
  "Ordinaria Especial": "oe"
}

const RequiredFields = {
  [NodeType.GAZETTE]: ["id", "name", "file", "type", "date", "number"],
  [NodeType.NORMATIVE]: ["id", "name", "normtype", "year", "state", "gazette"],
}

function isObject(object) {
  return object != null && typeof object === 'object'
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)

    if(areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
      return false
    }
  }
  return true
}

function clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function convertPropertiesToData(properties) {
  let data = {}

  for (const {label, value} of properties) {
    let val = ''

    if (value === null)
      val = null
    else if (typeof value !== 'object')
      val = value
    else if (Array.isArray(value))
      val = value.map(x => (typeof x === 'object' && 'label' in x && 'value' in x) ? x.value : x)
    else if ('label' in value && 'value' in value)
      val = value['value']

    data[label] = val
  }

  return data
}

async function loadFromAPI(url, useHttps = Boolean(parseInt(process.env.VUE_APP_USE_HPPTS))) {
  let nextPage = url
  let data = []

  while (nextPage) {
    const resp = await axios.get(nextPage)
    if (resp && resp.data) {
      if (resp.data.results)
        data.push(...resp.data.results)

      if (resp.data.next)
        nextPage = useHttps ? resp.data.next.replace('http://', 'https://') : resp.data.next
      else
        nextPage = null
    }
  }

  return data
}

function stringfyJson(object, breakSeparator='<br/>') {
  let string = ''
  for (const key in object) {
    string += JSON.stringify({[key]: object[key]}) + breakSeparator
  }
  return string
}

const Colors = [
  'darkred', 'green', 'purple', 'olive', 'teal', 'dimgray', 'sienna',
  '#6300ab', '#a75e00', '#be1234', '#b7b700', '#646464', '#1e086b'
]

const DICTIONARY = {
  id: 'Identificador',
  name: 'Nombre',
  summary: 'Resumen',
  number: 'Número',
  normtype: 'Tipo de Normativa',
  organism: 'Organismo emisior',
  year: 'Año',
  state: 'Estado',
  keywords: 'Palabras claves',
  gazette: 'Gaceta',
  derogatedby: 'Derogada por',
  modifiedby: 'Modificada por',
  derogateto: 'Modifica a',
  tags: 'Etiquetas',
  file: 'Archivo',
  type: 'Tipo',
  date: 'Fecha',
  revision: 'Revisión',
  data: 'Datos de la gaceta',
  startpage: 'Página de inicio',
  endpage: 'Página final',
}

export {
  DICTIONARY,
  Colors,
  NodeType,
  GazetteEditionType,
  RequiredFields,
  deepEqual,
  loadFromAPI,
  convertPropertiesToData,
  stringfyJson,
  clone,
}
