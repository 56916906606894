<template>
  <div>

    <!-- Classifiers Banner ("New" button, spinner) -->
    <div class="row row-margin">
      <q-banner class="bg-grey-4 text-grey-8 full-width"
                rounded
                style="margin-bottom: 15px; border: 1px solid #c6c6c6;">
        Clasificadores
        <q-btn @click="addNewEmptyTag"
               no-caps
               unelevated
               size="sm"
               style="margin-left: 10px;"
               color="green"
        >
          Nuevo
          <q-icon name="add"></q-icon>
        </q-btn>

        <q-spinner-ios v-if="showSpinner"
                       color="green"
                       size="1.2em"
                       :thickness="3"
                       style="margin-left: 15px"
        ></q-spinner-ios>

      </q-banner>
    </div>

    <!-- Row with input tags -->
    <template v-for="(tagItem, tagIndex) in classifications">
      <div class="row row-margin" :key="`tag-name-row-${tagItem.id}-${tagIndex}`">
        <div class="col padding-1" :key="`tag-name-${tagItem.id}-${tagIndex}`">
          <q-select outlined
                    square
                    use-input
                    v-model="tagItem.tag.name"
                    label="Nombre de la etiqueta"
                    input-debounce="0"
                    :options="tagNameOptions"
                    @filter="(val, update) => filterTagName(val, update, tagItem)"
                    @new-value="createNewTagName"
                    @input="(val) => updateTagValues(val, tagItem)"
                    :dense="true"
                    class="full-width"
                    style="padding: 0"
          ></q-select>
        </div>
        <div class="col padding-1" :key="`tag-value-${tagItem.id}-${tagIndex}`">
          <q-select outlined
                    square
                    use-input
                    v-model="tagItem.tag.value"
                    label="Valor de la etiqueta (opcional)"
                    input-debounce="0"
                    :options.sync="tagItem.valueOptions"
                    @filter="(val, update) => filterTagValue(val, update, tagItem, tagIndex, false)"
                    @new-value="(val, done) => createNewTagValue(val, done, tagItem, tagIndex, false)"
                    :dense="true"
                    class="full-width"
                    style="padding: 0"
          ></q-select>
        </div>
        <span style="padding: 5px;" :key="`btn-delete-${tagItem.id}-${tagIndex}`">
          <q-btn round flat color="red" icon="o_delete" size="md" @click="removeTag(tagItem, tagIndex)">
            <q-tooltip :delay="delayTooltip">Eliminar</q-tooltip>
          </q-btn>
        </span>
        <span :key="`btn-view-${tagItem.id}-${tagIndex}`" style="padding: 5px;">
          <q-btn round flat icon="remove_red_eye" size="md" style="color: #acaba4"
                 @click="showDetails(tagIndex)">
            <q-tooltip :delay="delayTooltip">
              <h6 class="text-center">Visualizar</h6>
              <div v-if="!tagItem.text">Texto: ""</div>
              <div v-else-if="tagItem.text.length < 30">Texto: "{{tagItem.text}}"</div>
              <div v-else>Texto: "{{tagItem.text.slice(0, 30) + '...'}}"</div>
            </q-tooltip>
          </q-btn>
        </span>
      </div>
    </template>

    <!-- Save button and separator-->
    <div>
      <q-separator style="margin-top: 20px;"></q-separator>

      <div>
        <q-btn label="Guardar"
               @click="save"
               flat
               color="primary"
               class="btn-right"
               style="margin-top: 10px; background-color: rgb(229, 234, 233);"
        ></q-btn>
      </div>
    </div>

    <!-- Dialog to visualize classification details -->
    <q-dialog v-model="showClassificationDetailsDialog">
      <q-card style="width: 750px;">

        <q-bar style="background-color: #f2f2f2; height: 40px">
          <div style="font-size: small">Detalles de la Etiqueta</div>

          <q-space/>

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip>Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh" class="scroll">
          <div class="row full-width padding-1 row-1">
            <div class="col col-shrink tag-label">Nombre:</div>
            <div class="col tag-value">
              {{(this.classifications[classificationIndex] && this.classifications[classificationIndex].tag.name) ||
                '*ninguno*'}}
            </div>
          </div>
          <div class="row full-width padding-1 row-1">
            <div class="col col-shrink tag-label">Valor:</div>
            <div class="col tag-value">
              {{(this.classifications[classificationIndex] && this.classifications[classificationIndex].tag.value) ||
                '*ninguno*'}}
            </div>
          </div>
          <div class="row full-width padding-1 row-1">
            <div class="col col-shrink tag-label">Texto:</div>
            <div class="col tag-value">
              {{(this.classifications[classificationIndex] && this.classifications[classificationIndex].text) ||
                '*ninguno*'}}
            </div>
          </div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px; height: 30px">
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog to add new tag with text from text editor -->
    <q-dialog v-model="showNewTagDialog" persistent>
      <q-card style="width: 750px;">

        <q-card-section style="background-color: #f2f2f2">
          <div v-if="showModifyButtons" class="text-h6">Modificar Etiqueta</div>
          <div v-else class="text-h6">Añadir Nueva Etiqueta</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh" class="scroll">

          <div class="row row-margin">
            <q-select outlined
                      square
                      use-input
                      v-model="newTagItem.tag.name"
                      label="Nombre de la etiqueta"
                      input-debounce="0"
                      :options="tagNameOptions"
                      @filter="(val, update) => filterTagName(val, update, newTagItem)"
                      @new-value="createNewTagName"
                      @input="(val) => updateTagValues(val, newTagItem)"
                      :dense="true"
                      class="full-width"
                      style="padding: 0"
            ></q-select>
          </div>
          <div class="row row-margin">
            <q-select outlined
                      square
                      use-input
                      v-model="newTagItem.tag.value"
                      label="Valor de la etiqueta (opcional)"
                      input-debounce="0"
                      :options.sync="valueOptionsOfNewTag"
                      @filter="(val, update) => filterTagValue(val, update, newTagItem, -1, true)"
                      @new-value="(val, done) => createNewTagValue(val, done, newTagItem, -1, true)"
                      :dense="true"
                      class="full-width"
                      style="padding: 0; max-heigth: 100px;"
            ></q-select>
          </div>

          <div class="row row-margin">
            <q-input v-model="newTagItem.text"
                     scroll
                     label="Texto que identifica la etiqueta"
                     :dense="true"
                     type="textarea"
                     class="full-width"
                     outlined
            >
            </q-input>
          </div>

        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px">
          <template v-if="showModifyButtons">
            <q-btn label="Cancelar" style="background-color: #dd0049; color: white;" v-close-popup></q-btn>
            <q-btn label="Modificar"
                   @click="modifyTag"
                   style="background-color: #02955e; color: white;"
                   v-close-popup
            ></q-btn>
          </template>
          <template v-else>
            <q-btn label="Cancelar" style="background-color: #dd0049; color: white;" v-close-popup></q-btn>
            <q-btn label="Añadir"
                   @click="addTagToClassifications"
                   style="background-color: #02955e; color: white;"
                   v-close-popup
            ></q-btn>
          </template>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog to modify tag from text editor -->
    <q-dialog v-model="showModifyTagDialog">
      <q-card style="width: 750px;">

        <q-bar style="background-color: #f2f2f2; height: 40px">
          <div style="font-size: small">Seleccione Etiqueta a modificar</div>

          <q-space/>

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip>Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh; padding: 10px;" class="scroll">

          <q-card v-for="(tagItem, tagIndex) in classifications" :key="tagIndex"
                  class="my-card"
                  @click="updateTag(tagItem, tagIndex)"
          >
            <q-card-section style="padding: 5px;">
              <div>
                <span class="color-tag-label">Nombre:</span>
                <span class="color-tag-value">{{tagItem.tag.name}}</span>
              </div>
              <div>
                <span class="color-tag-label">Valor:</span>
                <span class="color-tag-value">{{tagItem.tag.value}}</span>
              </div>
              <div>
                <span class="color-tag-label">Texto:</span>
                <span class="color-tag-value">{{tagItem.text}}</span>
              </div>
            </q-card-section>
          </q-card>

        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px; height: 30px">
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>


<script>
  import {
    NodeType,
    loadFromAPI,
    stringfyJson,
  } from '../util'
  import axios from 'axios'
  import {MUTATION, ACTION} from '../store/mutation-types'


  function getTagsByName(data) {
    let result = {}
    for (const {name, value} of data) {
      const values = result[name] || []
      if (!values.includes(value)) {
        values.push(value)
        result[name] = values
      }
    }
    return result
  }

  function findTagId(tags, tagItem) {
    for (const tag of tags) {
      if (tagItem.name === tag.name && tagItem.value === tag.value) {
        return tag.id
      }
    }
    return null
  }

  function classificationsToStrObj(classifications, sep='--') {
    let result = []
    for (const cls of classifications) {
      result.push(`${cls.tag.name} ${sep} ${cls.tag.value} ${sep} ${cls.text}`)
    }
    return result
  }

  export default {
    name: 'Classifiers',

    data() {
      return {
        text: '',
        allTags: {},
        delayTooltip: 300,
        tagNameOptions: [],
        classifications: [],
        showSpinner: false,
        classificationIndex: 0,
        valueOptionsOfNewTag: [],
        initialClassifications: [],
        showClassificationDetailsDialog: false,
      }
    },

    methods: {
      updateTagValues(val, tagItem) {
        tagItem.valueOptions = this.allTags[val]
      },

      filterTagName(val, update) {
        let tagNameOptions = Object.keys(this.allTags)

        if (val !== '') {
          tagNameOptions = Object.keys(this.allTags).filter(x => {
            return x.toLowerCase().indexOf(val.toLowerCase()) > -1
          })
        }
        update(() => {
          this.tagNameOptions = tagNameOptions
        })
      },

      filterTagValue(val, update, tagItem, tagIndex, isFromTextEditor) {
        if (!tagItem.tag || !tagItem.tag.name) {
          update()
          return
        }

        let valueOptions = this.allTags[tagItem.tag.name]

        if (val !== '') {
          valueOptions = this.allTags[tagItem.tag.name].filter(x => {
            return x && x.toLowerCase().indexOf(val.toLowerCase()) > -1
          })
        }

        update(() => {
          if (!isFromTextEditor) {
            tagItem.valueOptions = valueOptions
            this.classifications.splice(tagIndex, 1, tagItem)
          } else {
            this.valueOptionsOfNewTag = valueOptions
          }
        })
      },

      createNewTagName(val, done) {
        if (val.length > 0) {
          if (!Object.keys(this.allTags).includes(val)) {
            this.allTags[val] = []
          }
          done(val, 'add-unique')
        }
      },

      createNewTagValue(val, done, tagItem, tagIndex, isFromTextEditor) {
        if (tagItem.tag.name === '') {
          this.$q.notify({icon: 'warning', message: 'Debe crear el Nombre de la etiqueta primero'})
          return
        }

        if (val.length > 0 && Object.keys(this.allTags).includes(tagItem.tag.name)) {
          if (!this.allTags[tagItem.tag.name].includes(val)) {
            let valueOptions = this.allTags[tagItem.tag.name] || []
            valueOptions.push(val)
            tagItem.valueOptions = valueOptions
            if (!isFromTextEditor) {
              this.classifications.splice(tagIndex, 1, tagItem)
            }
          }
          done(val, 'add-unique')
        }
      },

      addNewEmptyTag() {
        this.classifications.push({
          id: null,
          tag: {
            id: null,
            name: '',
            value: ''
          },
          text: '',
          valueOptions: []
        })
      },

      showDetails(index) {
        this.showClassificationDetailsDialog = true
        this.classificationIndex = index
      },

      addTagToClassifications() {
        this.classifications.push(this.newTagItem)
        this.newTagItem = {id: null, tag: {id: null, name: '', value: ''}, text: '', valueOptions: []}
      },

      updateTag(tagItem, tagIndex) {
        this.newTagItem = JSON.parse(JSON.stringify(tagItem))
        this.newTagItem.text = this.textSelection
        this.newTagItem.index = tagIndex

        this.showModifyTagDialog = false
        this.showNewTagDialog = true
        this.showModifyButtons = true
      },

      modifyTag() {
        this.newTagItem.text = this.textSelection
        this.classifications[this.newTagItem.index] = this.newTagItem
      },

      save() {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        const areTagsWithoutName = this.classifications.filter(x => (!x.tag.name || x.tag.name === '')).length > 0

        if (areTagsWithoutName) {
          this.$q.notify({icon: 'warning', message: 'No se pueden guardar etiquetas sin nombre'})
          return
        }

        const notif = this.$q.notify({
          group: false, // required to be updatable
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: 'Guardando Cambios'
        })

        this.saveClassifications()
          .then(() => {
            this.$store.dispatch(ACTION.SET_REVISION_STATE, {revision: {
              state: 'editing',
              editor: this.$store.state.user ? this.$store.state.user.id : null
            }})
            this.$store.commit(MUTATION.SET_EDITOR_ICON, { value: true })

            notif({
              icon: 'done',
              spinner: false, // we reset the spinner setting so the icon can be displayed
              message: 'Cambios realizados!',
              timeout: 2500
            })
          })
          .catch(err => {
            let errorText = 'error desconocido'
            if (err && err.response) {
              errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
                : stringfyJson(err.response.data)
            }

            notif({
              icon: 'error',
              type: 'negative',
              spinner: false,
              message: 'Error guardando',
              caption: errorText,
              timeout: 3000
            })
          })
      },

      async saveClassifications() {
        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        const apiUrl = `${process.env.VUE_APP_API_HOST}/api`
        const allTags = await loadFromAPI(`${apiUrl}/tags/`)

        for (const cls of this.classifications) {
          let tagId = findTagId(allTags, cls.tag)

          if (!tagId) { // if tag not exists, create a new tag
            const data = {name: cls.tag.name, value: cls.tag.value}
            tagId = (await axios.post(`${apiUrl}/tags/`, data, config)).data.id
          }

          if (!cls.id) { // if classification not exists, create a new one
            const data = {tag: tagId, text: cls.text, normative: this.currentNode.id}
            await axios.post(`${apiUrl}/classifications/`, data, config)
          } else { // else, update classification
            const data = {tag: tagId, text: cls.text}
            await axios.patch(`${apiUrl}/classifications/${cls.id}/`, data, config)
          }
        }

        const self = this
        axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
          "action": "set",
          "instance": "classification",
          "user": self.$store.state.user.id,
          "fromvalue": stringfyJson(classificationsToStrObj(self.initialClassifications), '\n'),
          "tovalue": stringfyJson(classificationsToStrObj(self.classifications), '\n'),
          "details": `modify classifications of normative\nid:${this.currentNode.id}`
        }, config)

        await this.loadClassificationsByNormative()
      },

      async removeTag(tagItem, tagIndex) {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }
        const url = `${process.env.VUE_APP_API_HOST}/api/classifications/${tagItem.id}/`

        try {
          if (tagItem.id) {
            await axios.delete(url, config)
          }
          this.classifications.splice(tagIndex, 1)
        }
        catch (err) {
          const errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
            : stringfyJson(err.response.data)

          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Error eliminando',
            caption: errorText
          })
        }
      },

      async loadAllTags() {
        const url = `${process.env.VUE_APP_API_HOST}/api/tags/`
        const data = await loadFromAPI(url)
        this.allTags = getTagsByName(data)
      },

      async loadClassificationsByNormative() {
        this.classifications = await this.fetchClassificationsByNormative()
        this.initialClassifications = JSON.parse(JSON.stringify(this.classifications))
        this.$store.commit(MUTATION.SET_CLASSIFICATIONS, this.classifications)

        this.classifications.map((x) => {
          x.valueOptions = this.allTags[x.tag.name]
        })
      },

      async fetchClassificationsByNormative() {
        let classifications = []

        if (this.currentNode && this.currentNode.type === NodeType.NORMATIVE) {
          const url = `${process.env.VUE_APP_API_HOST}/api/normatives/${this.currentNode.id}/`
          const normativeData = (await axios.get(url)).data
          classifications = normativeData['tags']
        }
        return classifications
      }
    },

    computed: {
      textSelection() {
        return this.$store.state.textSelection
      },
      selectedNode() {
        return this.$store.state.selectedNode
      },
      currentNode() {
        const node = this.selectedNode.split('/')
        if (!node || node.length === 0)
          return null
        return {id: node[0], type: node[2]}
      },
      showNewTagDialog: {
        get: function () {
          return this.$store.state.showNewTagDialog
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_NEW_TAG_DIALOG, {value: newValue})
        }
      },
      showModifyTagDialog: {
        get: function () {
          return this.$store.state.showModifyTagDialog
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_MODIFY_TAG_DIALOG, {value: newValue})
        }
      },
      newTagItem: {
        get: function () {
          return this.$store.state.newTagItem
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_NEW_TAG_ITEM, newValue)
        }
      },
      showModifyButtons: {
        get: function () {
          return this.$store.state.showModifyButtons
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_MODIFY_BUTTONS, {value: newValue})
        }
      },
    },

    watch: {
      selectedNode() {
        this.showSpinner = true

        new Promise(res => {
          if (Object.keys(this.allTags).length === 0) {
            this.loadAllTags()
          }
          this.loadClassificationsByNormative()
          res()
        })
          .then(() => {
            this.showSpinner = false
          })
          .catch(() => {
            this.showSpinner = false
          })
      },
    },

    mounted() {
      this.showSpinner = true

      new Promise(resolve => {
        this.loadAllTags()
        this.loadClassificationsByNormative()
        resolve()
      })
        .then(() => {
          this.showSpinner = false
          this.initialClassifications = []
        })
        .catch(() => {
          this.showSpinner = false
        })
    }
  }
</script>


<style>
  .row-margin {
    margin: 5px;
  }

  .q-banner > div {
    font-size: revert;
  }

  .btn-right {
    float: right;
    margin: 5px;
  }

  .padding-1 {
    padding: 5px;
  }

  .tag-value {
    color: #454545;
    margin-left: 10px;
    max-height: 150px;
    overflow: auto;
  }

  .tag-label {
    color: #0b6750;
  }

  .row-1 {
    border: 1px solid #efefef;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    background-color: #f7f7f7;
  }

  .color-tag-label {
    color: teal;
    font-size: 15px;
  }

  .color-tag-value {
    color: #626262;
    margin-left: 10px;
  }

  .my-card {
    margin: 10px;
    padding: 1px;
    border: 0;
    transition: all .2s ease-in-out;
  }

  .my-card:hover {
    transform: scale(1.02);
    border: 1px solid #cacaca;
    background-color: #ececec;
  }

</style>