<template>
  <q-page>

    <!-- Loading spinner -->
    <div v-if="nodes.length===0" class="absolute-center">
      <div v-if="hasThisUserYearsAssigned" class="text-center">Cargando...</div>
      <div v-if="hasThisUserYearsAssigned" class="text-center">
        <q-spinner-dots color="primary" size="4em"></q-spinner-dots>
      </div>
      <div v-if="!hasThisUserYearsAssigned" class="text-center">No hay años asignados a este usuario</div>
    </div>

    <!-- Search box, Tree and right click context menu-->
    <div v-else>
      <!-- Search box-->
      <div class="q-pa-md fixed z-top" id="searchTreeContainer">
        <div class="q-gutter-md">
          <q-input
            v-model="searchTree"
            debounce="400"
            filled
            placeholder="Buscar"
            :dense="true"
            @keyup.esc="searchTree=''"
          >
            <template v-slot:append>
              <q-icon name="search"></q-icon>
            </template>
          </q-input>
        </div>
      </div>

      <!-- Tree and right click context menu-->
      <div class="q-pa-md q-gutter-sm">

        <!-- Right Click Menu -->
        <q-menu context-menu
                touch-position
                transition-show="scale"
                transition-hide="scale"
                @before-show="manageContextMenu"
        >

          <q-list dense style="min-width: 100px">

            <!-- Add new gazette -->
            <q-item clickable>
              <q-item-section>Añadir Gaceta</q-item-section>
              <q-item-section side>
                <q-icon name="keyboard_arrow_right"/>
              </q-item-section>

              <q-menu anchor="top end" self="top start">
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="showDialogAddNewGazetteOrNormative('Gaceta')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="add"></q-icon>
                    </q-item-section>
                    <q-item-section>Nueva Gaceta</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="showDialogAddFromUrl('Gaceta')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="o_link"></q-icon>
                    </q-item-section>
                    <q-item-section>Escanear desde URL</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>

            </q-item>

            <!-- Add new normative -->
            <q-item clickable>
              <q-item-section>Añadir Normativa</q-item-section>
              <q-item-section side>
                <q-icon name="keyboard_arrow_right"/>
              </q-item-section>

              <q-menu anchor="top end" self="top start">
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="showDialogAddNewGazetteOrNormative('Normativa')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="add"></q-icon>
                    </q-item-section>
                    <q-item-section>Nueva Normativa</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="showDialogAddFromUrl('Normativa')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="o_link"></q-icon>
                    </q-item-section>
                    <q-item-section>Escanear desde URL</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>

            <!-- Set mask as -->
            <q-separator v-if="showMarkAsOptionInContextMenu"></q-separator>
            <q-item clickable v-if="showMarkAsOptionInContextMenu">
              <q-item-section>Marcar como</q-item-section>
              <q-item-section side>
                <q-icon name="keyboard_arrow_right"/>
              </q-item-section>

              <q-menu anchor="top end" self="top start">
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="saveRevisionState('unmodified')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="o_menu_book"></q-icon>
                    </q-item-section>
                    <q-item-section>Sin modificar</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="saveRevisionState('editing')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="o_edit"></q-icon>
                    </q-item-section>
                    <q-item-section>En edición</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="saveRevisionState('finished')">
                    <q-item-section side>
                      <q-icon size="1.2em" name="o_done"></q-icon>
                    </q-item-section>
                    <q-item-section>Finalizada</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>

            <!-- Manage Directories -->
            <q-separator v-if="showDirectoriesOptionInContextMenu"></q-separator>
            <q-item v-if="showDirectoriesOptionInContextMenu"
                    @click="showDirectoriesDialog"
                    clickable
                    v-close-popup>
              <q-item-section>Administrar directorios</q-item-section>
            </q-item>

            <!-- Delete gazette/normative -->
            <q-separator v-if="showDeleteOptionInContextMenu"></q-separator>
            <q-item v-if="showDeleteOptionInContextMenu"
                    @click="deleteGazetteOrNormative"
                    clickable
                    v-close-popup>
              <q-item-section>Eliminar</q-item-section>
            </q-item>

            <!-- Show properties -->
            <q-separator></q-separator>
            <q-item v-if="showPropertiesOptionInContextMenu"
                    @click="showPropertiesDialog"
                    clickable
                    v-close-popup>
              <q-item-section>Propiedades</q-item-section>
            </q-item>

          </q-list>

        </q-menu>

        <!-- Tree content-->
        <q-tree id="tree"
                :nodes="nodes"
                node-key="id"
                color="primary"
                no-results-label="No hay resultados que mostrar"
                @lazy-load="loadTreeNodes"
                :selected.sync="selectedNode"
                :filter="$store.state.searchTree"
        >

          <template v-slot:default-header="prop">
            <div class="row items-center text-no-wrap no-wrap"
                 :data-type="prop.node.type"
                 :data-nodeId="prop.node.nodeId"
                 :data-label="prop.node.label"
                 :data-year="getNodeYear(prop.node)"
            >
              <q-icon v-if="prop.node.icon"
                      :name="prop.node.icon"
                      :data-type="prop.node.type"
                      :data-nodeId="prop.node.nodeId"
                      :data-label="prop.node.label"
                      :data-icon="prop.node.icon"
                      :data-year="getNodeYear(prop.node)"
                      class="q-icon-right-margin">
              </q-icon>
              {{ prop.node.label }}
              <q-tooltip :delay="1000" max-width="250px">{{ prop.node.label }}</q-tooltip>
            </div>
          </template>
        </q-tree>

      </div>
    </div>

    <!-- Dialog to edit properties of normative/gazette -->
    <q-dialog v-model="showProperties" persistent>
      <q-card style="width: 750px; position: absolute;" draggable @dragstart="dragStart" id="draggableDialog">

        <q-card-section style="background-color: #f2f2f2">
          <div class="text-h6">Propiedades</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh" class="scroll">
          <div v-if="showLoadPropertiesSpinner"
               style="padding-top: 40px; text-align: center; height: 200px;">
            <q-spinner-gears color="primary" size="5.5em"></q-spinner-gears>
            <div style="text-align: center; margin-top: 20px">Cargando...</div>
          </div>
          <properties></properties>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px">
          <q-btn label="Cancelar" @click="clearProperties" style="background-color: #dd0049; color: white;"
                 v-close-popup></q-btn>
          <q-btn label="Guardar" @click="saveProperties" style="background-color: #02955e; color: white;"
                 v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog to add new normative/gazette -->
    <q-dialog v-model="showAddNewGazetteOrNormativeDialog" persistent>
      <q-card style="width: 750px;" draggable @dragstart="dragStart" id="draggableDialog">

        <q-card-section style="background-color: #f2f2f2">
          <div class="text-h6">Añadir nueva {{ dialogNodeType }}</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh" class="scroll">
          <div v-if="showLoadPropertiesSpinner"
               style="padding-top: 40px; text-align: center; height: 200px;">
            <q-spinner-gears color="primary" size="5.5em"></q-spinner-gears>
            <div style="text-align: center; margin-top: 20px">Cargando...</div>
          </div>

          <properties></properties>

        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px">
          <q-btn label="Cancelar" style="background-color: #dd0049; color: white;" v-close-popup></q-btn>
          <q-btn label="Añadir" @click="saveNewGazetteOrNormative"
                 style="background-color: #02955e; color: white;"></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog to add new normative/gazette from URL -->
    <q-dialog v-model="showAddFromUrl" persistent>
      <q-card style="width: 750px;" draggable @dragstart="dragStart" id="draggableDialog">

        <q-card-section style="background-color: #f2f2f2;">
          <div class="text-h6">Añadir nueva {{ dialogNodeType }}</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh; padding: 20px 20px;" class="scroll">

          <div class="m-label">
            Ponga una url válida para escanear la {{ dialogNodeType }}
          </div>
          <q-input v-model="url"
                   outlined
                   :dense="true"
                   placeholder="https://www.gacetaoficial.gob.cu/..."
          ></q-input>

        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px">
          <q-btn label="Cancelar" style="background-color: #dd0049; color: white;" v-close-popup></q-btn>
          <q-btn label="Añadir" @click="scanUrl" style="background-color: #02955e; color: white;"></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog to manage directories -->
    <q-dialog v-model="showDirectories" persistent>
      <q-card style="width: 750px;" draggable @dragstart="dragStart" id="draggableDialog">

        <q-card-section style="background-color: #f2f2f2;">
          <div class="text-h6">Administrar directorios</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 70vh; padding: 20px 20px;" class="scroll">
          <div class="row row-margin">
            <div class="col padding-1">
              <directory-path></directory-path>
            </div>
            <span style="padding: 5px;">
              <q-btn id="add-dir-btn" round flat icon="o_add" size="md" @click="addDirectory">
                <q-tooltip>Añadir</q-tooltip>
              </q-btn>
            </span>
          </div>
          <div v-if="showLoadDirectoriesSpinner"
               style="padding-top: 40px; text-align: center; height: 200px;">
            <q-spinner-gears color="primary" size="5.5em"></q-spinner-gears>
            <div style="text-align: center; margin-top: 20px">Cargando...</div>
          </div>
        </q-card-section>

        <q-card-section style="max-height: 50vh; padding: 20px 20px;" class="scroll">
          <div class="row row-margin"
               v-for="(dirItem, dirIndex) in directoriesOfNormative"
               :key="`dir-${dirIndex}-${dirItem}`"
          >
            <div class="col padding-1 dir-label">{{ dirItem }}</div>
            <span class="dir-label" style="padding: 5px;" :key="`dir-btn-delete-${dirIndex}`">
              <q-btn round flat color="red" icon="o_delete" size="md" @click="removeDirectory(dirItem, dirIndex)">
                <q-tooltip :delay="500">Eliminar</q-tooltip>
              </q-btn>
            </span>
          </div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right" style="background-color: #f2f2f2; padding-right: 30px">
          <q-btn label="Cancelar" style="background-color: #dd0049; color: white;" v-close-popup></q-btn>
          <q-btn label="Guardar" @click="saveDirectories" style="background-color: #02955e; color: white;"></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-page>
</template>

<script>
  import {MUTATION, ACTION} from '../store/mutation-types'
  import axios from 'axios'
  import {
    NodeType,
    RequiredFields,
    GazetteEditionType,
    deepEqual,
    loadFromAPI,
    convertPropertiesToData,
    stringfyJson,
  } from '../util'
  import Properties from './Properties'
  import DirectoryPath from './DirectoryPath'
  import {createHash} from 'crypto'


  const ICON_BY_STATE = {
    unmodified: 'o_menu_book',
    editing: 'o_edit',
    finished: 'o_done',
  }

  async function fetchAllGazettesByYear(year) {
    let url = `${process.env.VUE_APP_API_HOST}/api/gazettes/year/${year}/`
    return loadFromAPI(url)
  }

  async function fetchAllNormativesByGazetteId(gazetteId) {
    let url = `${process.env.VUE_APP_API_HOST}/api/normatives/bygazette/${gazetteId}/`
    return loadFromAPI(url)
  }

  async function loadYears(nodes) {
    let url = `${process.env.VUE_APP_API_HOST}/api/gazette/years/`
    const years = (await axios.get(url)).data.results
    insertYearsIntoTreeNode(nodes, years)
  }

  function insertYearsIntoTreeNode(node, years) {
    for (const year of years) {
      node.push({
        label: `${year}`,
        lazy: true,
        type: NodeType.YEAR,
        nodeId: `${year}`,
        id: `${year}/null/${NodeType.YEAR}/${year}/null`,
        selectable: false
      })
    }
  }

  async function insertGazettesIntoTreeNode(node, gazettes) {
    const revisionUrl = `${process.env.VUE_APP_API_HOST}/api/revisions/`
    const revisions = await loadFromAPI(revisionUrl)
    let revisionsById = {}
    revisions.map(r => revisionsById[r.id] = r)

    for (const gazette of gazettes) {
      let icon = 'o_menu_book'

      if (gazette.revision) {
        const gazetteRevision = revisionsById[gazette.revision]

        if (gazetteRevision) {
          icon = ICON_BY_STATE[gazetteRevision.state]
        }
      }

      node.push({
          label: gazette.name,
          lazy: true,
          type: NodeType.GAZETTE,
          nodeId: gazette.id,
          id: `${gazette.id}/${gazette.file}/${NodeType.GAZETTE}/${gazette.name}/${gazette.id}`,
          icon: icon,
          revisionId: gazette.revision | null
        }
      )
    }
  }

  function loadNormatives(children, normatives, node) {
    const parentNode = node.id.split("/")
    for (const normative of normatives) {
      children.push({
          label: normative.name,
          type: NodeType.NORMATIVE,
          nodeId: normative.id,
          id: `${normative.id}/${parentNode[1]}/${NodeType.NORMATIVE}/${normative.name}/${parentNode[0]}/${parentNode[3]}`,
          icon: 'o_description'
        }
      )
    }
  }

  function findNode(nodes, nodeId) {
    for (const node of nodes) {
      if (node.nodeId === nodeId)
        return node
      if (node.children && node.children.length > 0) {
        const result = findNode(node.children, nodeId)
        if (result)
          return result
      }
    }
    return null
  }

  async function modifyYearTreeNode(year, nodes) {
    let node = node = findNode(nodes, `${year}`)

    if (!node) {
      insertYearsIntoTreeNode(nodes, [year])
      node = findNode(nodes, `${year}`)
    }

    const gazettes = await fetchAllGazettesByYear(year)
    node.children = []
    await insertGazettesIntoTreeNode(node.children, gazettes)
  }

  async function modifyGazetteNode(gazette, nodes) {
    let node = null
    for (const yearNode of nodes) {
      if (yearNode.children) {
        for (const gazetteNode of yearNode.children) {
          if (`${gazetteNode.nodeId}` === `${gazette}`) {
            node = gazetteNode
            break
          }
        }
      }
      if (node)
        break
    }
    if (node) {
      const normatives = await fetchAllNormativesByGazetteId(gazette)
      node.children = []
      loadNormatives(node.children, normatives, node)
    }
  }


  export default {
    name: 'TreeExplorer',

    components: {
      Properties,
      DirectoryPath,
    },

    data() {
      return {
        url: '',
        nodes: [],
        dialogNodeType: '',
        showProperties: false,
        showAddFromUrl: false,
        showDirectories: false,
        directoriesOfNormative: [],
        initialDirectoriesOfNormative: [],
        showMarkAsOptionInContextMenu: false,
        showDeleteOptionInContextMenu: false,
        showPropertiesOptionInContextMenu: false,
        showDirectoriesOptionInContextMenu: false,
        showAddNewGazetteOrNormativeDialog: false,
        backendMessagesDict: {
          'Normative extracted on gazette': 'Extraída en la gaceta',
          'Normative extracted without gazette': 'Extraída pero no asociada a ninguna gaceta',
          'Normative already exists on gazette': 'Ya existe en la gaceta',
          'Invalid url': 'Url no válida',
          'Gazette data not valid': 'Los datos extraídos no son válidos',
          'Gazette exist': 'La gaceta ya existe',
          'Extracted gazette': 'Gaceta extraída',
        },
        hasThisUserYearsAssigned: true,
      }
    },

    methods: {
      getNodeYear(node) {
        if (/\d{4,4}/.test(node.label))
          return /\d{4,4}/.exec(node.label)[0]

        if (/(?=[ -_])\d{4,4}/.test(node.id))
          return /(?=[ -_])\d{4,4}/.exec(node.id)[0]

        return null
      },

      loadTreeNodes({node, done}) {
        let children = []

        switch (node.type) {

          case NodeType.YEAR:
            fetchAllGazettesByYear(node.id.split("/")[0]).then(async data => {
              await insertGazettesIntoTreeNode(children, data)
              this.$store.state.searchTree = ''
              done(children)
            })
            break

          case NodeType.GAZETTE:
            fetchAllNormativesByGazetteId(node.id.split("/")[0]).then(data => {
              loadNormatives(children, data, node)
              this.$store.state.searchTree = ''
              done(children)
            })
            break
        }
      },

      manageContextMenu(e) {
        const nodeType = e.target.getAttribute('data-type')
        const nodeId = e.target.getAttribute('data-nodeId')
        const nodeLabel = e.target.getAttribute('data-label')
        const nodeYear = e.target.getAttribute('data-year')

        this.$store.commit(MUTATION.SET_RIGHT_CLICK_NODE, {nodeType: nodeType, nodeId: nodeId, nodeLabel: nodeLabel, nodeYear: nodeYear})

        this.showPropertiesOptionInContextMenu = nodeType && (nodeType === NodeType.GAZETTE || nodeType === NodeType.NORMATIVE)
        this.showDirectoriesOptionInContextMenu = nodeType && nodeType === NodeType.NORMATIVE
        this.showMarkAsOptionInContextMenu = nodeType && nodeType === NodeType.GAZETTE && this.user && this.user.is_admin
        this.showDeleteOptionInContextMenu = nodeType && (nodeType === NodeType.GAZETTE || nodeType === NodeType.NORMATIVE) && this.user && this.user.is_admin
      },

      showPropertiesDialog() {
        this.showProperties = true
        this.properties = []
        this.$store.commit(MUTATION.SET_INITIAL_PROPERTIES_DATA, {})
        this.$store.commit(MUTATION.SET_SHOW_UPLOAD_PDF_FILE_INPUT, {value: false})
      },

      saveProperties() {
        let data = convertPropertiesToData(this.properties)
        const noModification = deepEqual(data, this.$store.state.initialPropertiesData)

        if (noModification) {
          this.$q.notify({
            icon: 'warning',
            spinner: false, // we reset the spinner setting so the icon can be displayed
            message: 'No se realizaron cambios!',
            timeout: 2500
          })
        }
        else {
          if (!this.$store.state.user) {
            this.$q.notify({
              type: 'negative',
              icon: 'error',
              message: 'Necesita autenticarse para realizar esta operación',
              timeout: 3000
            })
            return
          }

          const notif = this.$q.notify({
            group: false, // required to be upda table
            timeout: 0, // we want to be in control when it gets dismissed
            spinner: true,
            message: 'Guardando...',
            caption: 'Propiedades'
          })

          const nodeType = this.$store.state.rightClickNode.nodeType
          const nodeId = this.$store.state.rightClickNode.nodeId
          const self = this

          // make header request with authentication token
          const config = {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`
            }
          }

          // convert keywords field from str to array
          if (nodeType === NodeType.NORMATIVE && typeof data['keywords'] === 'string') {
            data['keywords'] = data['keywords'].split(',').filter(x => {
              return /\w+/.test(x)
            }).map(x => x.trim())
          }

          axios
            .patch(`${process.env.VUE_APP_API_HOST}/api/${nodeType.toLowerCase()}s/${nodeId}/`, data, config)
            .then(() => {
              const node = findNode(this.nodes, nodeId)
              if (node.label !== data.name)
                node.label = data.name

              notif({
                icon: 'done',
                spinner: false, // we reset the spinner setting so the icon can be displayed
                message: 'Cambios guardados!',
                timeout: 2500
              })

              axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
                "action": "update",
                "instance": nodeType.toLowerCase(),
                "user": self.$store.state.user.id,
                "fromvalue": stringfyJson(self.$store.state.initialPropertiesData, '\n'),
                "tovalue": stringfyJson(data, '\n'),
                "details": `modify properties of ${nodeType.toLowerCase()}\nid: ${nodeId}\nname: ${data.name}`
              }, config)
            })
            .catch((err) => {
              let errorText = 'error desconocido'
              if (err && err.response) {
                errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
                  : stringfyJson(err.response.data)
              } else {
                errorText = stringfyJson(err)
              }

              notif({
                icon: 'error',
                spinner: false, // we reset the spinner setting so the icon can be displayed
                message: 'Error guardando',
                type: 'negative',
                html: true,
                caption: errorText,
                timeout: 5000,
              })
            })
        }
      },

      clearProperties() {
        this.properties = []
        this.$store.commit(MUTATION.SET_INITIAL_PROPERTIES_DATA, {})
      },

      showDialogAddNewGazetteOrNormative(nodeType) {
        this.$store.commit(MUTATION.SET_PROPERTIES, {value: []})
        this.$store.commit(MUTATION.SET_SHOW_LOAD_PROPERTIES_SPINNER, {value: true})
        this.$store.commit(MUTATION.SET_PDF_FILE, null)

        this.dialogNodeType = nodeType
        this.showAddNewGazetteOrNormativeDialog = true
        this.showUploadPdfFileInput = nodeType === 'Gaceta'
        const value = nodeType === 'Gaceta' ? NodeType.GAZETTE : NodeType.NORMATIVE
        const showPdfInput = value === NodeType.GAZETTE

        this.$store.commit(MUTATION.SET_NEW_GAZETTE_OR_NORMATIVE, {value: value})
        this.$store.commit(MUTATION.SET_SHOW_UPLOAD_PDF_FILE_INPUT, {value: showPdfInput})
      },

      saveNewGazetteOrNormative() {
        if (this.dialogNodeType === 'Gaceta') {
          this.saveGazette()
        } else if (this.dialogNodeType === 'Normativa') {
          this.saveNormative()
        }
      },

      async saveGazette() {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        // make data request
        const gazetteData = convertPropertiesToData(this.properties)
        const gazetteUrl = `${process.env.VUE_APP_API_HOST}/api/gazettes/`

        const gyear = new Date(gazetteData.date).getFullYear()
        const gtype = GazetteEditionType[gazetteData.type]
        const gfile = `goc-${gyear}-${gtype}${gazetteData.number}.pdf`
        const gdate = gazetteData.date.replaceAll('/', '-')
        const self = this

        gazetteData['id'] = gfile
        gazetteData['file'] = gfile
        gazetteData['revision'] = null
        gazetteData['date'] = gdate

        // verify required fields
        if (Object.values(gazetteData).includes('') || !this.$store.state.pdfFile) {
          this.$q.notify({icon: 'warning', message: 'No pueden haber campos vacíos'})
          return
        }

        const notif = this.$q.notify({
          group: false, // required to be upda table
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: 'Guardando...',
          caption: 'Guardando nueva gaceta'
        })

        // send post request to save gazette
        axios
          .post(gazetteUrl, gazetteData, config)
          .then(async () => {
            if (this.$store.state.pdfFile) {
              const formData = new FormData()
              formData.append('file', this.$store.state.pdfFile)
              const uploadUrl = `${process.env.VUE_APP_API_HOST}/api/upload/${gfile}/`
              const configMultipart = {...config}
              configMultipart.headers['Content-Type'] = 'multipart/form-data'
              await axios.post(uploadUrl, this.$store.state.pdfFile, configMultipart)
            }

            modifyYearTreeNode(gyear, this.nodes)

            notif({icon: 'done', message: 'Gaceta guardada', spinner: false, timeout: 2500})
            this.showAddNewGazetteOrNormativeDialog = false

            axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
              "action": "create",
              "instance": "gazette",
              "details": "create manually",
              "user": self.$store.state.user.id
            }, {headers: { Authorization: `JWT ${this.$store.state.jwt}` }})
          })
          .catch((err) => {
            let errorText = 'error desconocido'
            if (err && err.response) {
              if (err.response.status === 403) {
                errorText = 'No tiene permisos para realizar esta acción'
              } else {
                const errorKey = Object.keys(err.response.data)[0]
                errorText = `${errorKey}: ${err.response.data[errorKey]}`

                if (err.response.data[errorKey][0] === 'gazette with this id already exists.') {
                  errorText = `La gaceta ya existe`
                } else {
                  errorText = stringfyJson(err.response.data)
                }
              }
            }

            notif({
              icon: 'error',
              spinner: false,
              message: 'Error guardando gaceta',
              type: 'negative',
              html: true,
              caption: errorText,
              timeout: 5000
            })
          })
      },

      async saveNormative() {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        const normativeData = convertPropertiesToData(this.properties)
        const normativeUrl = `${process.env.VUE_APP_API_HOST}/api/normatives/`
        const self = this

        normativeData['id'] = createHash('sha1').update(normativeData['name'] + normativeData['summary']).digest('hex')
        normativeData['keywords'] = normativeData['keywords'].split(',').filter(x => {
          return /\w+/.test(x)
        })
        normativeData['organism'] = normativeData['organism'] === '' ? null : normativeData['organism']
        normativeData['number'] = normativeData['number'] === '' ? -1 : parseInt(normativeData['number'])

        // verify required fields
        for (const field in normativeData) {
          if (RequiredFields[NodeType.NORMATIVE].includes(field)) {
            if (!normativeData[field] || normativeData[field] === '') {
              this.$q.notify({icon: 'warning', message: 'Los campos con * son obligatorios'})
              return
            }
          }
        }

        // verify if normative already exist by checking specific fields
        let urlCheck = `${normativeUrl}?number=${normativeData['number']}&year=${normativeData['year']}&normtype=${normativeData['normtype']}`
        const notNumberedTypes = ["Convocatoria", "Nota de Presentación de Cartas Credenciales", "Acuerdo", "Proclama", "Resolución Conjunta"]
        if (notNumberedTypes.includes(normativeData['normtype'])) {
          urlCheck = `${normativeUrl}?name=${normativeData['name']}&year=${normativeData['year']}&normtype=${normativeData['normtype']}`
        }
        const resp = await axios.get(urlCheck)
        const normativeExist = Boolean(resp.data.count)

        const notif = this.$q.notify({
          group: false, // required to be upda table
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: 'Guardando...',
          caption: 'Guardando nueva normativa'
        })

        if (normativeExist) {
          notif({
            icon: 'error',
            spinner: false,
            type: 'negative',
            message: 'Ya existe una normativa con esos campos',
            caption: '',
            timeout: 2500
          })
          return
        }

        // send post request to save normative
        axios
          .post(normativeUrl, normativeData, config)
          .then(() => {
            modifyGazetteNode(normativeData.gazette, this.nodes)

            notif({icon: 'done', message: 'Normativa', caption: '', spinner: false, timeout: 2500})
            this.showAddNewGazetteOrNormativeDialog = false

            axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
              "action": "create",
              "instance": "normative",
              "details": "manual",
              "user": self.$store.state.user.id
            }, config)
          })
          .catch((err) => {
            let errorText = 'error desconocido'
            if (err && err.response) {
              if (err.response.status === 403) {
                errorText = 'No tiene permisos para realizar esta acción'
              } else {
                const errorKey = Object.keys(err.response.data)[0]
                errorText = `${errorKey}: ${err.response.data[errorKey]}`

                if (err.response.data[errorKey][0] === 'normative with this id already exists.') {
                  errorText = `La normativa ya existe`
                } else {
                  errorText = stringfyJson(err.response.data)
                }
              }
            }

            notif({
              icon: 'error',
              spinner: false,
              type: 'negative',
              message: 'Error guardando normativa',
              caption: errorText,
              timeout: 2500
            })
          })
      },

      showDialogAddFromUrl(nodeType) {
        this.url = ''
        this.dialogNodeType = nodeType
        this.showAddFromUrl = true
      },

      scanUrl() {
        if (!this.$store.state.user) {
          this.$q.notify({
            type: 'negative',
            icon: 'error',
            message: 'Necesita autenticarse para realizar esta operación',
            timeout: 2500
          })
          return
        }

        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }
        const nodeType = this.dialogNodeType === 'Normativa' ? NodeType.NORMATIVE : NodeType.GAZETTE
        const scrapUrl = `${process.env.VUE_APP_API_HOST}/api/${nodeType.toLowerCase()}/scrap/?use_proxy=${true}&url=${this.url}&deep=${true}`
        const self = this

        const notif = this.$q.notify({
          group: false, // required to be upda table
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: `Escaneando ${this.dialogNodeType}...`,
          caption: `Extrayendo ${this.dialogNodeType} del sitio oficial`
        })

        axios
          .get(scrapUrl, config)
          .then((resp) => {
            this.showAddFromUrl = false

            let year = null

            if (resp.data && resp.data.status === 'ok') {
              if (nodeType === NodeType.GAZETTE) {
                year = new Date(resp.data.gazette.date).getFullYear()
              }
              else {
                if (resp.data.normative.gazette && /\d{4}/.test(resp.data.normative.gazette))
                  year = parseInt(/\d{4}/.exec(resp.data.normative.gazette)[0])
                else
                  year = resp.data.normative.year
              }

              if (this.user && (this.user.is_admin || this.user.profile.availableYears.includes(year))) {
                if (nodeType === NodeType.GAZETTE)
                  modifyYearTreeNode(year, this.nodes)
                else
                  modifyGazetteNode(resp.data.normative.gazette, this.nodes)
              }

              let caption = resp.data.details || ''
              for (const msg in self.backendMessagesDict) {
                caption = caption.replace(msg, self.backendMessagesDict[msg])
              }

              notif({
                icon: 'done',
                message: `Extración de ${this.dialogNodeType}`,
                caption: caption,
                spinner: false,
                timeout: 5000
              })

              if (!caption.startsWith('Ya existe en la gaceta')) {
                axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
                  "action": "create",
                  "instance": nodeType.toLowerCase(),
                  "details": `scan from url ${self.url}`,
                  "user": self.$store.state.user.id
                }, config)
              }
            }
            else {
              notif({
                icon: 'error',
                type: 'negative',
                message: `Error al guardar ${this.dialogNodeType}`,
                caption: resp.data.details,
                spinner: false,
                timeout: 5000
              })
            }
          })
          .catch((err) => {
            let errorText = 'error desconocido'
            if (err && err.response) {
              errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
                : stringfyJson(err.response.data)
            }

            notif({
              icon: 'error',
              message: `Error`,
              type: 'negative',
              html: true,
              caption: errorText,
              spinner: false,
              timeout: 5000
            })
          })
      },

      async loadYearsOfUser(user) {
        this.nodes = []

        if (user && !user.is_admin) {
          const allYears = (await axios.get(`${process.env.VUE_APP_API_HOST}/api/gazette/years/`)).data.results
          const availableYears = user.profile.availableYears.filter(year => allYears.includes(year))

          insertYearsIntoTreeNode(this.nodes, availableYears)
        }
        else {
          await loadYears(this.nodes)
        }

        this.hasThisUserYearsAssigned = this.nodes.length !== 0
      },

      async saveRevisionState(state) {
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        try {
          const nodeId = this.$store.state.rightClickNode.nodeId
          const node = findNode(this.nodes, nodeId)
          const revisionId = node.revisionId

          if (state === 'finished') { // extract editor username for confirm dialog
            let confirmationText = `Esta gaceta no tiene un editor definido. ¿Desea marcarla de todas formas como finalizada?`
            if (revisionId > 0) {
              const revResp = await axios.get(`${process.env.VUE_APP_API_HOST}/api/revisions/${node.revisionId}/`)
              if (revResp.data.editor) {
                const userResp = await axios.get(`${process.env.VUE_APP_API_HOST}/api/users/${revResp.data.editor}/`, config)
                confirmationText = `¿Desea marcar como finalizada la edición de esta gaceta realizada por el editor "${userResp.data.username}"?`
              }
            }

            const self = this
            this.$q.dialog({
              title: 'Mensaje de confirmación',
              message: confirmationText,
              cancel: true,
              persistent: true
            }).onOk(() => {
              const revision = {
                state: state,
                reviewer: this.user.id | null
              }
              self.$store.dispatch(ACTION.SET_REVISION_STATE, {revision: revision, isRightClick: true})
              node.icon = ICON_BY_STATE[state]
              node.revisionId = revisionId
            })

          }
          else {
            const revision = {state: state}
            this.$store.dispatch(ACTION.SET_REVISION_STATE, {revision: revision, isRightClick: true})
            node.icon = ICON_BY_STATE[state]
            node.revisionId = revisionId
          }
        }
        catch (err) {
          this.$q.notify({
            icon: 'error',
            type: 'negative',
            message: 'Error modificando el estado de la revisión',
            caption: err.message
          })
        }
      },

      showDirectoriesDialog() {
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }
        this.dirInputText = ''
        this.showDirectories = true
        this.directoriesOfNormative = []
        this.initialDirectoriesOfNormative = []
        this.showLoadDirectoriesSpinner = true
        const normativeId = this.$store.state.rightClickNode.nodeId
        const self = this

        axios
          .get(`${process.env.VUE_APP_API_HOST}/api/directories/bynormative/${normativeId}/`, config)
          .then(res => {
            self.directoriesOfNormative = res.data.results || []
            self.initialDirectoriesOfNormative = [...self.directoriesOfNormative]
            self.showLoadDirectoriesSpinner = false
          })
      },

      saveDirectories() {
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }
        const normativeId = this.$store.state.rightClickNode.nodeId
        const notif = this.$q.notify({
          group: false, // required to be upda table
          timeout: 0, // we want to be in control when it gets dismissed
          spinner: true,
          message: 'Guardando...',
          caption: 'Directorios'
        })
        const self = this

        axios
          .post(`${process.env.VUE_APP_API_HOST}/api/directories/bynormative/${normativeId}/`, this.directoriesOfNormative, config)
          .then(() => {
            notif({
              icon: 'done',
              type: 'positive',
              spinner: false, // we reset the spinner setting so the icon can be displayed
              message: 'Cambios guardados!',
              caption: 'Directorios modificados',
              timeout: 2500
            })
            this.showDirectories = false

            axios.post(`${process.env.VUE_APP_API_HOST}/api/logs/`, {
              "action": "set",
              "instance": "directory",
              "fromvalue": stringfyJson(self.initialDirectoriesOfNormative, '\n'),
              "tovalue": stringfyJson(self.directoriesOfNormative, '\n'),
              "user": self.$store.state.user.id,
              "details": `modify directories of normative\nid: ${normativeId}`
            }, config)
          })
          .catch((err) => {
            let errorText = 'error desconocido'
            if (err && err.response) {
              errorText = err.response.status === 403 ? 'No tiene permisos para realizar esta acción'
                : stringfyJson(err.response.data)
            }

            notif({
              icon: 'error',
              spinner: false, // we reset the spinner setting so the icon can be displayed
              message: 'Error guardando',
              type: 'negative',
              html: true,
              caption: errorText,
              timeout: 5000,
            })
            this.showDirectories = false
          })
      },

      addDirectory() {
        if (this.$store.state.directoryTextInput) {
          if (!this.directoriesOfNormative.includes(this.dirInputText)) {
            this.directoriesOfNormative.splice(0, 0, this.dirInputText)
            this.dirInputText = ''
          }
        }
      },

      removeDirectory(dirItem, dirIndex) {
        this.directoriesOfNormative.splice(dirIndex, 1)
      },

      deleteGazetteOrNormative() {
        const nodeType = this.$store.state.rightClickNode.nodeType
        const nodeId = this.$store.state.rightClickNode.nodeId
        const nodeLabel = this.$store.state.rightClickNode.nodeLabel
        const nodeYear = this.$store.state.rightClickNode.nodeYear
        const nodeTypeEs = nodeType === NodeType.GAZETTE ? 'Gaceta' : 'Normativa'

        const self = this
        const url = `${process.env.VUE_APP_API_HOST}/api/${nodeType.toLowerCase()}s/${nodeId}/`

        // make header request with authentication token
        const config = {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`
          }
        }

        this.$q.dialog({
          title: `Eliminar ${nodeTypeEs}`,
          message: `Esta operación es irreversible. ¿Está seguro que desea eliminar "${nodeLabel}"?`,
          cancel: true,
          persistent: true,
        }).onOk(() => {

          const notif = this.$q.notify({
            group: false, // required to be upda table
            timeout: 0, // we want to be in control when it gets dismissed
            spinner: true,
            message: `Eliminando ${nodeTypeEs}...`,
          })

          axios.delete(url, config)
            .then(() => {
              notif({
                icon: 'done',
                type: 'positive',
                message: `Eliminación de ${nodeTypeEs} satisfactoria`,
                caption: `"${nodeLabel}" eliminada!`,
                spinner: false,
                timeout: 5000
              })

              if (nodeYear) {
                modifyYearTreeNode(nodeYear, self.nodes)
              }
            })
            .catch(err => {
              notif({
                icon: 'error',
                type: 'negative',
                message: 'Error eliminando',
                caption: err.message,
                spinner: false,
                timeout: 5000
              })
            })
        })
      },

      dragStart(e) {
        const style = window.getComputedStyle(e.target, null);
        e.dataTransfer.setData("text/plain",
          (parseInt(style.getPropertyValue("left"), 10) - e.clientX)
          + ','
          + (parseInt(style.getPropertyValue("top"), 10) - e.clientY)
        )
      },

      drop(e) {
        const offset = e.dataTransfer.getData("text/plain").split(',')
        let dm = document.getElementById('draggableDialog')

        dm.style.left = (e.clientX + parseInt(offset[0], 10)) + 'px'
        dm.style.top = (e.clientY + parseInt(offset[1], 10)) + 'px'

        e.preventDefault()
        return false
      },

      dragOver(e) {
        e.preventDefault()
        return false
      },
  },

    computed: {
      user() {
        return this.$store.state.user
      },
      reloadGazettes() {
        return this.$store.state.reloadGazettes
      },
      properties: {
        get: function () {
          return this.$store.state.properties
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_PROPERTIES, {value: newValue})
        }
      },
      searchTree: {
        get: function () {
          return this.$store.state.searchTree
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SEARCH_TREE, {value: newValue})
        }
      },
      selectedNode: {
        get: function () {
          return this.$store.state.selectedNode
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SELECTED_NODE, newValue)
        }
      },
      showLoadPropertiesSpinner: {
        get: function () {
          return this.$store.state.showLoadPropertiesSpinner
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_LOAD_PROPERTIES_SPINNER, {value: newValue})
        }
      },
      showLoadDirectoriesSpinner: {
        get: function () {
          return this.$store.state.showLoadDirectoriesSpinner
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_SHOW_LOAD_DIRECTORIES_SPINNER, {value: newValue})
        }
      },
      setEditorIcon: {
        get: function () {
          return this.$store.state.setEditorIcon
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_EDITOR_ICON, {value: newValue})
        }
      },
      dirInputText: {
        get: function () {
          return this.$store.state.directoryTextInput
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_DIRECTORY_TEXT_INPUT, {value: newValue})
        }
      },
    },

    watch: {
      user(newValue) {
        this.loadYearsOfUser(newValue)
      },
      setEditorIcon(newValue) {
        if (newValue) {
          const gazetteId = this.selectedNode.split('/')[4]
          const node = findNode(this.nodes, gazetteId)
          node.icon = ICON_BY_STATE['editing']
        }
        this.$store.commit(MUTATION.SET_EDITOR_ICON, {value: false})
      },
      reloadGazettes(newValue) {
        if (newValue) {
          this.loadYearsOfUser(this.user)
        }
      },
    },

    mounted() {
      this.loadYearsOfUser(this.user)

      document.body.addEventListener('dragover', this.dragOver, false);
      document.body.addEventListener('drop', this.drop, false);
    }
  }
</script>

<style>
  .q-icon-right-margin {
    margin-right: 5px;
  }

  #searchTreeContainer {
    padding-right: 20px;
    margin-top: 8px;
    background-image: linear-gradient(to bottom, rgb(245, 245, 245), rgba(240, 240, 240, 0.95));
  }

  #tree {
    margin-top: 70px;
  }

  .m-label {
    background-color: #f2f2f2;
    padding: 8px 7px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #ebe6e3;
    color: #7a7a7a;
  }

  .dir-label {
    word-break: break-word;
    border-bottom: 1px solid #e1e1e1;
    color: #393636;
  }

  #add-dir-btn {
    color: white;
    background-color: rgb(2, 149, 94);
    border-radius: 7%;
  }

  /*i[data-icon=o_edit] {*/
  /*color: #c6b318;*/
  /*}*/
  /*i[data-icon=o_done] {*/
  /*color: #09bd09;*/
  /*}*/
  /*i[data-icon=o_menu_book] {*/
  /*color: #4d4d4d;*/
  /*}*/

</style>
