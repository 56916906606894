<template>
  <div>
    <!-- the rest of fields -->
    <template v-for="field in properties">
      <div class="row row-margin" :key="field.id">

        <q-input v-if="field.type === 'date'"
                 v-model="field.value"
                 :label="getLabel(field)"
                 mask="####-##-##"
                 :dense="true"
                 filled
                 class="full-width"
                 style="padding: 0"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="field.value">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-select v-else-if="field.type === 'options'"
                  filled
                  use-input
                  emit-value
                  map-options
                  v-model="field.value"
                  :label="getLabel(field)"
                  input-debounce="0"
                  :options="field.filterOptions"
                  @filter="(val, update, abortfn) => filterFn(val, update, abortfn, field)"
                  @filter-abort="abort"
                  @new-value="(val, done) => createValue(val, done, field)"
                  :dense="true"
                  class="full-width"
                  style="padding: 0"
        ></q-select>

        <q-select v-else-if="field.type === 'multipleOptions'"
                  filled
                  v-model="field.value"
                  :label="getLabel(field)"
                  use-input
                  use-chips
                  multiple
                  emit-value
                  map-options
                  input-debounce="0"
                  :options="field.filterOptions"
                  @filter="(val, update, abortfn) => filterFn(val, update, abortfn, field)"
                  @filter-abort="abort"
                  :dense="true"
                  class="full-width"
                  style="padding: 0"
        ></q-select>

        <q-input v-else
                 v-model="field.value"
                 :label="getLabel(field)"
                 :dense="true"
                 :type="field.type"
                 class="full-width"
                 outlined
        >
        </q-input>

      </div>
    </template>

    <!-- pdf upload field -->
    <div class="row row-margin">
      <q-file v-if="showUploadPdfFileInput"
              v-model="pdfFile"
              outlined
              :dense="true"
              accept=".pdf"
              clearable
              label="PDF de la gaceta *"
              @input="changePdfFile"
      >
        <template v-slot:prepend>
          <q-icon name="attach_file" />
        </template>
      </q-file>
    </div>
  </div>
</template>


<script>
  import axios from 'axios'
  import { MUTATION } from '../store/mutation-types'
  import {
    DICTIONARY,
    NodeType,
    RequiredFields,
    convertPropertiesToData,
  } from '../util'


  const NORMATIVE_EXCLUDE_FIELDS = ['id', 'file', 'tags', 'revision', 'directories', 'text', 'similarity']

  function getInputType(djangoFieldType, hasOptions) {
    switch (djangoFieldType) {
      case 'CharField':
        return hasOptions ? 'options' : 'text'
      case 'DateField':
        return 'date'
      case 'IntegerField':
        return 'number'
      case 'ArrayField':
      case 'TextField':
        return 'textarea'
      case 'ForeignKey':
        return 'options'
      case 'ManyToManyField':
        return 'multipleOptions'
      case 'DecimalField':
        return 'decimal'
      default:
        return null
    }
  }


  export default {
    name: 'Properties',

    data() {
      return {
        pdfFile: null,
        nodeType: null,
        pdfFileNamePattern: /goc-\d{4}-\w+\d{1,4}\.pdf/, // goc-2004-o7.pdf
      }
    },

    computed: {
      rightClickNode() {
        return this.$store.state.rightClickNode
      },
      showUploadPdfFileInput() {
        return this.$store.state.properties.length
          && this.$store.state.showUploadPdfFileInput;
      },
      properties: {
        get: function () {
          return this.$store.state.properties
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_PROPERTIES, {value: newValue})
        }
      },
      newGazetteOrNormative: {
        get: function () {
          return this.$store.state.newGazetteOrNormative
        },
        set: function (newValue) {
          this.$store.commit(MUTATION.SET_NEW_GAZETTE_OR_NORMATIVE, {value: newValue})
        }
      },
    },

    methods: {
      async loadFieldsInner(nodeType, nodeId, nodeData) {
        const res = await axios.get(`${process.env.VUE_APP_API_HOST}/api/${nodeType.toLowerCase()}/fields/`)
        const fields = res.data.results ? res.data.results : []

        let nodeFieldsOptions = await axios.get(`${process.env.VUE_APP_API_HOST}/api/${nodeType.toLowerCase()}/fields/values/`)
        nodeFieldsOptions = 'results' in nodeFieldsOptions.data ? nodeFieldsOptions.data.results : []

        this.properties = []

        for (const field in fields) {

          if (NORMATIVE_EXCLUDE_FIELDS.includes(field))
            continue

          const fieldType = fields[field]
          const hasOptions = field in nodeFieldsOptions
          const type = getInputType(fieldType, hasOptions)
          const value = nodeData ? nodeData[field] : (type === 'multipleOptions' ? [] : '')

          if (type) {
            this.properties.push({
              'label': field,
              'type': type,
              'value': value,
              'options': hasOptions ? nodeFieldsOptions[field] : [],
              'filterOptions': hasOptions ? nodeFieldsOptions[field] : [],
            })
          }
        }

        this.$store.commit(MUTATION.SET_SHOW_LOAD_PROPERTIES_SPINNER, {value: false})
        this.$store.commit(MUTATION.SET_INITIAL_PROPERTIES_DATA, convertPropertiesToData(this.properties))
      },

      async loadFields(nodeType, nodeId) {
        const partialUrl = nodeType === NodeType.GAZETTE ? 'gazettes' : 'normative/byid'
        const node = await axios.get(`${process.env.VUE_APP_API_HOST}/api/${partialUrl}/${nodeId}/`)
        const nodeData = node.data
        await this.loadFieldsInner(nodeType, nodeId, nodeData)
      },

      async loadEmptyFields(nodeType) {
        await this.loadFieldsInner(nodeType, null, null)

        if (nodeType && this.rightClickNode.nodeType === NodeType.GAZETTE) {
          const gazetteField = this.properties.filter(x => { return x.label === 'gazette' })[0]
          if (gazetteField) {
            gazetteField.value = this.rightClickNode.nodeId
          }
        }
      },

      async filterFn(val, update, abort, field) {
        const gazetteFields = ['derogatedby', 'modifiedby', 'derogateto']

        if (field.label === 'gazette') {
          if (field.options.length === 0) {
            let options = await axios.get(`${process.env.VUE_APP_API_HOST}/api/gazette/values/`)
            field.options = options.data.results.map(x => ({label: x.name, value: x.id}))
          }
        }
        else if (gazetteFields.includes(field.label)) {
          if (field.options.length === 0) {
            let options = await axios.get(`${process.env.VUE_APP_API_HOST}/api/normative/values/`)
            field.options = options.data.results.map(x => ({label: x.name, value: x.id}))

            this.properties.filter(x => gazetteFields.includes(x.label)).map(f => {f.options = field.options})
          }
        }

        update(() => {
          if (val === '') {
            field.filterOptions = field.options
          }
          else {
            const needle = val.toLowerCase()
            field.filterOptions = field.options.filter(v => {
              const value = v instanceof Object ? v.label : v
              return value.toLowerCase().indexOf(needle) > -1
            })
          }
        })
      },

      createValue(val, done, field) {
        if (val.length > 0) {
          if (!field.options.includes(val)) {
            field.options.push(val)
          }
          done(val, 'add-unique')
        }
      },

      abort() {
      },

      changePdfFile(file) {
        this.$store.commit(MUTATION.SET_PDF_FILE, file)
      },

      getLabel(field) {
        const label = field.label
        const requiredFields = this.nodeType ? RequiredFields[this.nodeType] : []
        const translateLabel = DICTIONARY[label] || label

        return requiredFields.includes(label) ? `${translateLabel} *` : translateLabel
      },
    },

    mounted() {
      if (this.newGazetteOrNormative) {
        this.loadEmptyFields(this.newGazetteOrNormative)
        this.nodeType = this.newGazetteOrNormative
        this.newGazetteOrNormative = null
      }
      else {
        const nodeType = this.$store.state.rightClickNode.nodeType
        const nodeId = this.$store.state.rightClickNode.nodeId
        this.nodeType = nodeType

        this.$store.commit(MUTATION.SET_SHOW_LOAD_PROPERTIES_SPINNER, {value: true})
        this.loadFields(nodeType, nodeId)
      }
    },

  }
</script>


<style>

</style>
